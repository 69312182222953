import React, { useEffect, useMemo, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useLocation } from 'react-router-dom';
import { reportEvents, embedType } from 'constants/pbiReports';
import './PbiAdminReport.css';
import CommonLoader from '../Alerts/CommonLoader';
import _ from 'underscore';
import { getEmbedConfig } from 'api/pbiReports';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { ConfirmPopupWithoutCancel } from 'library/ConfirmPopup';
import { useTranslation } from 'react-i18next';
import { DateLib } from '@hme-cloud/utility-common';
import { startOfTheWeekFilter } from 'components/PbiReportScreen/PBIReportConstant';
import { sendMetrics } from 'components/PbiReportScreen/helpers/SendMetrics';


// eslint-disable-next-line complexity
const PbiAdminReport = () => {
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const type = pathArray[pathArray.length - 1];
    const { t } = useTranslation();
    const [reportsLoaded, setReportsLoaded] = useState(false);
    const { loaded, rendered, error, firstRendered, unloaded } = reportEvents;
    const { report } = embedType;
    const [showCommonLoader, setShowCommonLoader] = useState(true);
    const [reportConfig, setReportConfig] = useState({});
    const { reportId, embedUrl, embedToken } = reportConfig;
    const [startWatcher, setStartWatcher] = useState(false);
    const [isFirstRendered, setIsFirstRendered] = useState(false);
    const profile = baseAuthService.getProfile();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [iframeClassName, setIframeClassName] = useState('Embed-container-outliers');

    // decoding admin token
    const pbiMetricsID = useMemo(() =>
        profile.unique_name + profile.name + new DateLib().getUnixTime(), [type]
    );

    /**
     * verify if token is available
     * @param {object} expiresDate date string
     * @return {boolean} false if token is available
     */
    const checkTokenExpired = (expiresDate) => {
        const diff = new DateLib(expiresDate).diff(new DateLib().format(), 'minutes');
        if (diff < 3) {
            return false;
        }

        return true;
    };

    /**
     * Handle the power bi event logs
     * @param {string} eventType power bi event name
     * @param {object} errorResponse response object when the pbiEvent is an error
     */
    const handlePowerBiEvent = (eventType, errorResponse = {}) => {
        sendMetrics({ pbiMetricsID, eventType, type, pbiMetricsEmail: profile.unique_name, errorResponse });
    };

    // Call API to get Power Bi Token
    const getReports = async () => {
        setShowCommonLoader(true);
        setStartWatcher(false);
        const localConfig = JSON.parse(localStorage.getItem(`pbi-token-${type}`));
        const isTokenAvaliable = checkTokenExpired(localConfig?.expiresDate);
        try {
            if (!localConfig || !isTokenAvaliable) {
                const data = await getEmbedConfig({
                    reportType: type
                });
                const expiresDate = new DateLib().addMinutes(20).format();
                const configToSave = {
                    token: data.embedToken,
                    expiresDate
                };
                localStorage.setItem(`pbi-token-${type}`, JSON.stringify(configToSave));
                setReportConfig(data);
                setReportsLoaded(false);
            } else {
                const config = await getEmbedConfig({
                    reportType: type,
                    hasToken: true
                });
                setReportConfig({
                    ...config,
                    embedToken: localConfig.token
                });
                setReportsLoaded(false);
            }
        } catch (badRequest) {
            const errorResponse = {
                message: 'EnvironmentVariableError',
                detailedMessage: 'An environmental variable has an error and mismatch the real value of the resource.'
            };
            handlePowerBiEvent(error, errorResponse);
        }
    };

    const refreshToken = async () => {
        const localConfig = JSON.parse(localStorage.getItem(`pbi-token-${type}`));
        const isTokenAvaliable = checkTokenExpired(localConfig?.expiresDate);
        try {
            if (!localConfig || !isTokenAvaliable) {
                const data = await getEmbedConfig({
                    reportType: type
                });
                const expiresDate = new DateLib().addMinutes(20).format();
                const configToSave = {
                    token: data.embedToken,
                    expiresDate
                };
                localStorage.setItem(`pbi-token-${type}`, JSON.stringify(configToSave));
                await window.report.setAccessToken(data.embedToken);
            }
        } catch (badRequest) {
            const errorResponse = {
                message: 'EnvironmentVariableError',
                detailedMessage: 'An environmental variable has an error and mismatch the real value of the resource.'
            };
            handlePowerBiEvent(error, errorResponse);
        }
    };
    /**
     * set the reportLoaded state when the report configuration object is updated
     * @param {object} reportConfig report configuration object
     */
    useEffect(() => {
        if (!_.isEmpty(reportConfig)) {
            setShowCommonLoader(false);
            setReportsLoaded(true);
            setStartWatcher(true);
        }

        // clean up before change report
        return () => {
            setReportsLoaded(false);
        };
    }, [reportConfig]);
    /**
     *  keep watching if the token expires
     * @param {boolean} startWatcher
     */
    useEffect(() => {
        const localConfig = JSON.parse(localStorage.getItem(`pbi-token-${type}`));
        let isTokenExpired = false;
        let tokenWatcher;
        tokenWatcher = setInterval(() => {
            const isTokenAvaliable = checkTokenExpired(localConfig.expiresDate);
            if (!isTokenAvaliable) {
                isTokenExpired = true;
                if (type==='ghost-cars-report') {
                    setShowCancelModal(true);
                } else {
                    refreshToken();
                }
            }
        }, 30000);
        if (!startWatcher || isTokenExpired) {
            clearInterval(tokenWatcher);
            tokenWatcher = false;
        }
        return () => {
            clearInterval(tokenWatcher);
            tokenWatcher = false;
        };
    }, [startWatcher]);

    /**
     * Execute the unloaded event when the report is unmounted
     * @param {string} type report type
     */
    useEffect(() => {
        setIsFirstRendered(false);
        getReports();
        switch (type) {
            case 'ghost-cars-report':
            case 'speed-of-service-detection':
                setIframeClassName('Embed-container-ghost-cars');
                break;
            case 'speed-of-service':
                setIframeClassName('Embed-container-sos');
                break;
            default:
                setIframeClassName('Embed-container-outliers');
                break;
        }
        return () => {
            handlePowerBiEvent(unloaded);
        };
    }, [type]);

    useEffect(() => {
        if (isFirstRendered) {
            handlePowerBiEvent(firstRendered);
        }
    }, [isFirstRendered]);

    const onReportLoaded = (event) => {
        console.log('report loaded');
        handlePowerBiEvent(event.type);
    };
    const onReportRendered = (event) => {
        console.log('report rendered');
        handlePowerBiEvent(event.type);
        setIsFirstRendered(true);
    };

    const handleError = (event) => {
        const errorResponse = event.detail;
        console.error(errorResponse);
        handlePowerBiEvent(error, errorResponse);
    };

    const handleconfirm = () => {
        getReports();
        setShowCancelModal(false);
    };

    const cancelMessageWarning = (
        <div className="text-dark text-lg modal-message">
            <p>
                {t('pbiReports__modal-message')}
                <br />
                {t('pbiReports__modal-instructions-message')}
            </p>
        </div>
    );

    return (
        <section className="adminReportsPage">
            {showCommonLoader && (<CommonLoader showLoader={showCommonLoader} message={'common__loading'} />)}
            {!showCommonLoader && (
                <>
                    <div>
                        <ConfirmPopupWithoutCancel
                            show={showCancelModal}
                            message={cancelMessageWarning}
                            onConfirm={() => handleconfirm()}
                            confirmationVerb={t('common__continue')}
                        />
                    </div>
                    <div className="my-5">
                        {
                            reportsLoaded &&
                                <PowerBIEmbed
                                    embedConfig={{
                                        type: report,
                                        id: reportId,
                                        embedUrl: embedUrl,
                                        tokenType: models.TokenType.Embed,
                                        permissions: models.Permissions.All,
                                        accessToken: embedToken,
                                        filters: [
                                            startOfTheWeekFilter(t('common__sunday'), type)
                                        ],
                                        settings: {
                                            panes: {
                                                filters: {
                                                    expanded: false,
                                                    visible: false
                                                }
                                            }
                                        }
                                    }}
                                    eventHandlers={
                                        // eslint-disable-next-line no-undef
                                        new Map([
                                            [loaded, (event) => onReportLoaded(event)],
                                            [rendered, (event) => onReportRendered(event)],
                                            [error, (event) => handleError(event)]
                                        ])
                                    }
                                    cssClassName={`${iframeClassName} Embed-container-admin`}
                                    getEmbeddedComponent={(embeddedReport) => {
                                        window.report = embeddedReport;
                                    }}
                                />
                        }
                    </div>
                </>
            )}
        </section>
    );
};

export default PbiAdminReport;
