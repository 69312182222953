// TODO: Please add dynamic loading of partner types when we will add Partner View/Edit logic
export const PARTNER_TYPES = {
    VAIO: 'Vaio',
    NITRO: 'Nitro',
    JTECH: 'JTech',
    DXS: 'DXS'
};

export const trimFields = [
    'phoneNumber',
    'mobilePhone',
    'firstName',
    'lastName',
    'userEmail'
];
