import React, { useState, useEffect } from 'react';
import { CenterLoader } from 'components/Common/CenterLoader';
import { useTranslation } from 'react-i18next';
import { NotificationsList } from 'library/NotificationsList';
import { Header } from '../components/Header';
import { Sections } from '../components/Sections';

export const EditSSOManagement = ({ domain = '', email ='', setIsSaving, setFormValue }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <div className="hme-create_edit-sso-management-page__wrapper">
            { isLoading ? (
                <CenterLoader>{t('common__loading')}</CenterLoader>
                ) : (
                    <div className="hme-tab-panel-content">
                        <NotificationsList />
                        <Header isEdit/>
                        <div className="sso-form-container">
                            <Sections
                                domain={domain}
                                email={email}
                                setIsSaving={setIsSaving}
                                setFormValue={setFormValue}
                            />
                        </div>
                    </div>
                )}
            {/* <Footer isSubmitEnabled={saveEnabled} onCancel={onCancel} onApply={onTokenCreate} /> */}
        </div>
    );
};
