import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { isAdmin } from 'services/Auth';
import { compose, cond, T } from 'ramda';
import { CenterLoader } from 'components/Common/CenterLoader';
import { NotificationsList } from 'library/NotificationsList';
import { Trans } from 'react-i18next';
import { Header } from './components/Header';
import { useSSOManagementList } from './hooks/useSSOManagementList';
import { List } from './components/List';
import { PAGE_DATA } from 'constants/paginationDefault';
import { Paginate } from 'library/Paginate';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';
import { deleteSSOInfo } from '../services/ssoManagementService';

import './SSOManagementList.scss';

const SSOManagementLayout = ({
    ssoFullList,
    ssoPaginatedList,
    pageNumber,
    itemsPerPage,
    totalRows,
    onPaginationChange,
    fetchSSODetails
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [delIdx, setDelIdx] = useState(null);

    useEffect(()=> {
        if (location.state && location.state.saved) {
            addSuccessNotification(t('settings_sso_configuration_account_added_success_message'));
        }
    }, [location]);

    const handleConfirmRemoveClick = useCallback(async () => {
        try {
            await deleteSSOInfo({
                id: delIdx
            });
            await fetchSSODetails();
            addSuccessNotification(t('settings_sso_configuration_delete_success_message'));
        } catch (err) {
            addErrorNotification(t('settings_sso_configuration_delete__error_message'));
            console.error('Could not delete SSO info', err);
        }
    }, [ssoPaginatedList, delIdx]);

    useEffect(() => {
        navigate(location.pathname, {});
    }, []);

    return (
        <div className="hme-sso-management-page__wrapper">
            <div className="hme-sso-management-page__content">
                <NotificationsList />
                <Header />
                <List
                    ssoPaginatedList={ssoPaginatedList}
                    handleConfirmRemoveClick={handleConfirmRemoveClick}
                    setDelIdx={setDelIdx}
                />
                <Paginate
                    className="hme-sso-management-page__paginate"
                    page={pageNumber}
                    recordsPerPage={itemsPerPage}
                    pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                    total={totalRows}
                    onChange={onPaginationChange}
                    hideSinglePage
                />
            </div>
        </div>);
};

const SSOManagementComponent = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <CenterLoader>
                <Trans i18nKey="common__loading" />
            </CenterLoader>
        )
    ],
    [T, (props) => <SSOManagementLayout {...props} />]
]);

const SSOManagementComponentLoader = () => {
    const state = useSSOManagementList();
    return <SSOManagementComponent {...state} />;
};

export const SSOManagementPage = compose(
        withAccess({
            checkAccess: () => isAdmin()
        }),
        withHMELayout({
            contentClasses: ['hme-sso-management-page']
        })
)(SSOManagementComponentLoader);
