import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { getDataAsync, postDataAsync, putDataAsync } from 'Api';
import { deviceConfig } from 'constants/device';
import AuthenticationService from 'components/Security/AuthenticationService';
import { settingApi } from 'api/settingApi';
import { DEFAULT_IP } from 'constants/login';

const {
    zoomNitro: { applySettingsMinVersion: applySettingsMinVersionNitro },
    zoom: {
        applySettingsMinVersion: applySettingsMinVersionZoom,
        applySettingsMaxVersion: applySettingsMaxVersionZoom
    }
} = deviceConfig;

const getSearchString = (obj) =>
    Object.keys(obj)
            .reduce((params, key) => {
                params.set(key, obj[key]);
                return params;
            }, new URLSearchParams())
            .toString();

// TODO: Remove this method after migrating to getAccounts
export const loadAccounts = async ({ mask, sourceDevice, selectedDeviceType, limit }) => {
    const filters = {
        limit,
        searchUserEmail: mask,
        deviceIsActive: 1,
        deviceTypeId: 1
    };

    switch (selectedDeviceType) {
        case 'zoom':
            filters.deviceMaxMainVersion = applySettingsMaxVersionZoom;
            filters.deviceMinMainVersion = sourceDevice ? sourceDevice.Device_MainVersion : applySettingsMinVersionZoom;

            break;
        case 'zoomNitro':
            filters.deviceMinMainVersion = applySettingsMinVersionNitro;

            break;
        case 'nexeo':
            filters.deviceTypeId = 5;

            break;
        default:
            break;
    }

    if (sourceDevice) {
        filters.excludeDeviceUID = sourceDevice.Device_UID;
    }

    const search = getSearchString(filters);
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.searchUserAccounts}`;
    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const getAccounts = async (params) => {
    const search = getSearchString(params);
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.searchUserAccounts}`;
    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

const getUsersQueryParams = (params) => {
    if (!params) return '';

    const {
        page = 0,
        recordsPerPage = 10,
        searchValue = '',
        selectValue = '',
        sortColumn = '',
        sortingType = 'ASC'
    } = params;

    return new URLSearchParams({
        psize: recordsPerPage,
        pno: page + 1,
        criteria: searchValue?.trim(),
        filter: searchValue ? selectValue : '',
        Sortby: sortColumn,
        sortType: sortingType,
    }).toString();
}

export const getUsers = async (params) => {
    const queryParams = params ? `&${getUsersQueryParams(params)}` : '';

    // fetch & filter users functionality is taken from 'actions/users' file
    const authService = new AuthenticationService(Config.authBaseUrl);
    const isAdmin = authService.isAdmin();
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getUsers}?isAdmin=${Number(isAdmin)}${queryParams}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    // filter users for non-admin accounts
    const userDetails = authService.getProfile();
    const { usersList } = response;

    return !isAdmin ? usersList.filter((u) => u.User_UID !== userDetails.User_UID) : usersList;
};

export const getAdminAccounts = async ({
    pageNumber,
    itemsPerPage = 10,
    search = '',
    searchField = '',
    appliedFilters = {},
    sortColumn = '',
    sortingType = ''
}) => {
    const params = {
        pagenumber: pageNumber || 1,
        search: encodeURIComponent(search),
        searchfield: searchField,
        sortcolumn: sortColumn,
        sorttype: sortingType,
        perpage: itemsPerPage,
        ...appliedFilters
    };

    const queryParams = new URLSearchParams(params).toString();

    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAdminAccountsList}?${queryParams}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

const getUrlByAccountType = (type, email) => {
    const accountsUrlByTypeMap = {
        expired: CommonConstants.apiUrls.getExpiredAccounts,
        renewal: CommonConstants.apiUrls.getRenewalAccounts,
        free: CommonConstants.apiUrls.getFreeAccounts
    };

    return accountsUrlByTypeMap[type]
        ? `${Config.apiBaseUrl}${accountsUrlByTypeMap[type]}${email}`
        : '';
};

export const sendAccountsReport = async (type, profile) => {
    const email = profile && profile.unique_name;

    if (!email) {
        throw new Error('Email is not defined');
    }
    const url = getUrlByAccountType(type, email);

    if (!url) {
        throw new Error('Report url is not defined');
    }

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const getFilters = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAccountFilters}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const getUserAccountDetails = async (uuid, fetchAccountOwner = false) => {
    const searchQuery = getSearchString({
        uuid,
        fetchAccountOwner
    });

    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAdminAcount}?${searchQuery}`;

    const { status, data: [ownerAccountData] = [] } = await getDataAsync(url);

    if (!status) {
        throw new Error('Request failed');
    }

    return ownerAccountData;
};

export const sendActivationEmail = async (account) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.activateAccount}?`;

    const { status } = await postDataAsync(url, account);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return 'admin__user__resend-activation-email-success';
};

export const resendActivationEmail = async (userEmail) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.resendActivationEmail}?`;

    const { status } = await postDataAsync(url, { userEmail });

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return 'admin__user__resend-activation-email-success';
};

export const saveAdminAccount = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.saveAdminAccount}`;

    const response = await postDataAsync(url, payload);

    if (!response.status) {
        if (response.reason === 'emailused') {
            throw new Error('admin__account__notification--email-used-error');
        }

        if (response.reason === 'dtdsconnectionerror') {
            throw new Error('admin__account__notification--dtds-connection-error');
        }

        throw new Error('admin__account__notification--request-error');
    }

    return response.data;
};

export const updateAdminAccount = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updateAdminAccount}`;

    const response = await postDataAsync(url, payload);

    if (!response.status) {
        if (response.reason === 'emailused') {
            throw new Error('admin__account__notification--email-used-error');
        }

        if (response.reason === 'zoomaccount') {
            throw new Error('admin__account__notification--zoom-devices-error');
        }

        if (response.reason === 'dtdsconnectionerror') {
            throw new Error('admin__account__notification--dtds-connection-error');
        }

        throw new Error('admin__account__notification--request-error');
    }

    return response.data;
};

export const getAccountStoresByUserUID = async (userUID, selectedDeviceType) => {
    const response = await settingApi.getAccountStoresByUserUID({ userUID, selectedDeviceType, isDeviceUpgrade: true });

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const getAccountData = async (isPartnerAccount = false) => {
    const url = isPartnerAccount ?
        `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartnerUserDetails}`
            :
        `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAccountInfo}`;

    const { status, ...data } = await getDataAsync(url);

    if (!status) {
        throw new Error('Request failed');
    }

    return data;
};

/**
 * Will verify current password for provided uuId
 */
export const verifyCurrentPassword = async ({ uuId, currentPassword }, isPartnerUser = false) => {

    let url;

    if (isPartnerUser) {
        url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.verifyPartnerCredential}`;
    } else {
        url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.verifycredential}`;
    }


    const { status, validPassword } = await postDataAsync(url, { uuId, password: currentPassword });

    if (!status) {
        throw new Error('Request failed');
    }

    return { status, validPassword };
};

export const agreeWithTOS = async ({
    uuId,
    UserID,
    ownerAccountId,
    isTOSAgreed,
    userAgent = navigator.userAgent,
    ip = DEFAULT_IP
}, isPartnerUser = false) => {
    let  url;

    if(isPartnerUser){
        url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.partnerUserAgreeTOS}`;
    } else {
        url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.agreeAccountTOS}`;
    }


    const dto = {
        uuId,
        UserID,
        AccountTOSBrowser: userAgent,
        AccountTOSIP: ip,
        ownerAccountId,
        AccountTOSAgree: isTOSAgreed
    };

    const { status, ...data } = await postDataAsync(url, dto);

    if (!status) {
        throw new Error('Request failed');
    }

    return { status, ...data };
};

export const changeAccountData = async (newAccountData) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.changeAccountData}`;

    const { status, ...data } = await postDataAsync(url, newAccountData);

    if (!status) {
        throw new Error(data.message || 'Request failed');
    }

    return { status, ...data };
};

export const changePassword = async (credentials, isPartnerUser = false) => {
    let url;

    if( isPartnerUser ) {
        url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.changePartnerUserPassword}`;
    } else {
        url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.changePassword}`;
    }

    const { status, ...data } = await postDataAsync(url, credentials);

    if (!status) {
        throw new Error(data.message || 'Request failed');
    }

    return { status, ...data };
};

export const generateNewToken = ({ userEmail, newPassword, isPartner }) => {
    const url = `${Config.authBaseUrl}${Config.tokenPath}`;

    const dto = {
        username: userEmail,
        password: newPassword,
        isPartner: isPartner
    };

    return postDataAsync(url, dto);
};

export const saveBrands = async (brands) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updateBrandSharing}`;

    const data = {
        brands,
    };

    try {
        return await putDataAsync(url, data);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }
}

export const getCompanyInfo = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getCompany}?`;

    const response = await getDataAsync(url);

    if (!response) {
        throw new Error('common__error--internal-server');
    }

    return response;
};

export const saveCompany = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.saveCompany}?`;

    const response = await postDataAsync(url, payload);

    if (!response) {
        throw new Error('common__error--internal-server');
    }

    return response;
};

export const saveViewFranchise = async (viewFranchise) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.saveViewFranchise}`;
    const payload = {
        viewFranchise,
    };

    const response = await postDataAsync(url, payload);

    if (!response || !response.status) {
        throw new Error('common__error--internal-server');
    }

    return response;
};

export const fetchAccountOwners = async (queryParams = {}, { signal } = {}) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAccountOwners}?${getSearchString(queryParams)}`;

    const response = await getDataAsync(url, { signal });

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response;
}
