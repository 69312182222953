import React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import classNames from 'classnames';

import './Label.scss';

/**
 * @description accessible component that represents a label for an input element.
 *
 * @docs https://www.radix-ui.com/primitives/docs/components/label
 * @docs https://ui.shadcn.com/docs/components/label
 */
export const Label = React.forwardRef(({ className, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={classNames('hme-form-label', className)}
        {...props}
    />
));
Label.displayName = LabelPrimitive.Root.displayName;
