import { sendImportFile } from 'Api';

const searchParams = {
    filterOutNitroEssentialsStores: true
};

export const uploadImportFile = async (file) => {
    if (!file) {
        return;
    }

    const data = new FormData();
    data.append('file', file);

    await sendImportFile(data, searchParams);
};
