import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Header } from '../../Common/Header/Header';
import './ListInput.css';

class ListInput extends Component {
  constructor(props) {
    super(props);

    this.listContainer = React.createRef();
    this.selectedOption = React.createRef();
  }

  scrollToActiveOption() {
    const { listContainer: { current : list }, selectedOption: { current: activeOption } } = this;

    if (list) {
      list.scrollTop = (activeOption && activeOption.offsetTop) || 0;
    }
  }

  componentDidMount() {
    this.scrollToActiveOption();
  }

  render() {
    const { className, title, options, zebra, scroll, value } = this.props;

    return (
      <div className={classNames(
        className,
        'hme-input list-input',
        {
          'list-input--zebra': zebra,
          'list-input--scroll': scroll,
        }
      )}>
        <Header type="small">{title}</Header>
        <div
          className="list-input__list"
          ref={this.listContainer}
        >
          <ul>
            {options.map(option => {
              const matchedOption = value === option.id;
              return <li
                className={classNames({
                  'list-input__list__option--selected': matchedOption,
                })}
                ref={matchedOption && this.selectedOption}
                key={option.id}
                onClick={() =>
                  {
                    this.props.onChange(option.id)
                  }}
              >{option.text}</li>
            })}
          </ul>
        </div>
      </div>
    );
  }
}

ListInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }))
};

export { ListInput };
