import React  from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { SearchOptionable } from 'library/SearchOptionable';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { searchSelectOptions } from '../constants/adminStoreSelectSearchOptions';

import './Header.scss';

export const Header = ({
    filterParams,
    filtersCount,
    onFiltersReset,
    onSearchParamsChange,
    onSearch,
}) => {
    const { t } = useTranslation();
    return <div className="hme-admin-store-header">
        <div className="hme-admin-store-header-title-wrapper">
            <Title>{t('common__stores')}</Title>
            <SearchOptionable
                value={filterParams}
                onChange={onSearchParamsChange}
                searchSelectOptions={searchSelectOptions}
                onSearch={onSearch}
            />
            <div className="hme-admin-accounts-filters-count">
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>
        </div>
    </div>;
};

Header.propTypes = {
    filterParams: PropTypes.shape({
        selectValue: PropTypes.string,
        searchValue: PropTypes.string
    }).isRequired,
    onSearchParamsChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
};
