import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'cva';
import { always } from 'ramda';

import './Button.scss';

const defaultOnClick = always();

const buttonVariants = cva('hme-form-button', {
    variants: {
        variant: {
            default: 'hme-form-button--default',
            primary: 'hme-form-button--primary'
        },
        size: {
            default: 'hme-form-button--size-default',
            lg: 'hme-form-button--size-lg'
        }
    },
    defaultVariants: {
        variant: 'default',
        size: 'default'
    }
});

const Button = React.forwardRef(
        ({ className, variant, size, asChild = false, disabled, onClick = defaultOnClick, ...props }, ref) => {
            const Comp = asChild ? Slot : 'button';
            return (
                <Comp
                    className={buttonVariants({ variant, size, className })}
                    ref={ref}
                    disabled={disabled}
                    onClick={!disabled ? onClick : defaultOnClick}
                    {...props}
                />
            );
        }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
