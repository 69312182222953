import axios from 'axios';
import i18next from 'i18next';

import { Config } from './Config';
import AuthenticationService, { baseAuthService } from './components/Security/AuthenticationService';
import { CommonConstants } from './Constants';
/**
Api class: GET, POST and DELETE api methods to be called from different components across the application.
*/
class Api {
    /**
     * This constructor is used to set initial state
     * @param  {input} authService
     */
    constructor(authService) {
    // TODO: Start using baseAuthService instance
        this.authService = authService || new AuthenticationService(Config.authBaseUrl);
    }

    /**
     * Method for POST API calls
     * @param  {input} url
     * @param  {input} data
     * @param  {input} callback
     * @param  {input} errorCallback
     * @param  {input} headers
     */
    postData(url, data, callback, errorCallback, headers) {
    // TODO: Using postDataAsync
        if (!headers) {
            headers = {};
            if (this.authService.getToken()) {
                headers = {
                    'Content-Type': 'application/json',
                    'Content-Language': i18next.resolvedLanguage,
                    'Authorization': 'Bearer ' + this.authService.getToken()
                };
                // In Admin Portal, add the UserUID of the Account Owner/User to request header
                if (this.authService.getUUID()) {
                    headers = { ...headers, 'UserUID': this.authService.getUUID() };
                }
            } else {
                headers = {
                    'Content-Type': 'application/json',
                    'Content-Language': i18next.resolvedLanguage,
                };
            }
        }
        return window.fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
                .then((response) => response.json())
                .then(callback)
                .catch((error) => {
                    if (errorCallback) {
                        errorCallback(error);
                    }
                    console.log(error);
                });
    }

    /**
     * Method for GET API calls
     * @param  {input} url
     * @param  {input} callback
     */
    getData(url, callback) {
    // TODO: Using getDataAsync
        window.fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': i18next.resolvedLanguage,
                'Authorization': 'Bearer ' + this.authService.getToken()
            }
        })
                .then((response) => response.json())
                .then(callback)
                .catch((error) => {
                    console.log(error);
                });
    }

    /**
     * Method for POST API Delete calls
     * @param  {input} url
     * @param  {input} callback
     */
    deleteData(url, callback) {
    // TODO: Using deleteDataAsync
    // TODO: Return promise if callback is not passed
        window.fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': i18next.resolvedLanguage,
                'Authorization': 'Bearer ' + this.authService.getToken()
            }
        })
                .then((response) => response.json())
                .then(callback)
                .catch((error) => {
                    console.log(error);
                });
    }

    /**
     * Method to get getPublicIp
     * @return  {Promise} getPublicIp
     */
    getPublicIp() {
        const url = Config.apiBaseUrl + CommonConstants.apiUrls.getUserIP;
        return getDataAsync(url);
    }
}

const authService = new AuthenticationService(Config.authBaseUrl);

const defaultHeaders = {
    'Content-Type': 'application/json'
};

const getHeaders = (otherHeaders) => {
    const calculatedHeaders = {
        'Content-Language': i18next.resolvedLanguage,
    };

    const headers = otherHeaders ? {
        ...defaultHeaders,
        ...calculatedHeaders,
        ...otherHeaders,
    } : {
        ...defaultHeaders,
        ...calculatedHeaders,
    };
    const token = baseAuthService.getToken();

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return headers;
};

export const postDataAsync = (url, data, otherHeaders, overrideOptions = {}) => axios({
    method: 'post',
    data,
    url,
    responseType: 'json',
    headers: getHeaders(otherHeaders),
    ...overrideOptions
}).then((data) => data.data);

export const patchDataAsync = async (url, data, otherHeaders) => {
    const requestData = {
        method: 'patch',
        data,
        url,
        responseType: 'json',
        headers: getHeaders(otherHeaders)
    };

    const response = await axios(requestData);
    return response.data;
};

export const putDataAsync = async (url, data, otherHeaders) => {
    const requestData = {
        method: 'put',
        data,
        url,
        responseType: 'json',
        headers: getHeaders(otherHeaders)
    };

    const response = await axios(requestData);
    return response.data;
};

export const getDataAsync = (url, otherHeaders, signal) => axios({
    method: 'get',
    data: null,
    signal: signal,
    url,
    headers: getHeaders(otherHeaders)
}).then((data) => data.data);

export const deleteDataAsync = (url, params, otherHeaders) => axios({
    method: 'delete',
    data: params,
    url,
    headers: getHeaders(otherHeaders)
}).then(({ data }) => data);

/**
 * Method to getExportFile
 * @param  {input} exportType
 * @return  {Object} getExportFile
 */
export function getExportFile({ url, exportType, params }) {
    return axios({
        method: 'get',
        data: null,
        url,
        responseType: 'blob',
        params,
        headers: {
            'Authorization': `Bearer ${authService.getToken()}`,
            'Content-Language': i18next.resolvedLanguage,
            'Content-Type': exportType === 'csv' ? 'text/csv' : 'application/json'
        }
    }).then((data) => data.data);
}

/**
 * Method to sendImportFile
 * @param  {input} data
 * @return  {Promise} sendImportFile HTTP data
 */
export function sendImportFile(data, searchParams = {}) {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.importGroup}?${getSearchString(searchParams)}`;

    return axios({
        method: 'post',
        url,
        data,
        headers: {
            Authorization: `Bearer ${authService.getToken()}`,
            'Content-Language': i18next.resolvedLanguage,
        }
    }).then((data) => data);
}

export const downloadBlobRequest = (url, data, fileName, requestOptions) => axios({
    method: 'post',
    data,
    url,
    responseType: 'blob',
    ...requestOptions,
    headers: getHeaders(requestOptions ? requestOptions.headers : null),
}).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
        document.body.removeChild(link);
    }, 3000);
});

export const uploadDataAsync = async (url, data, requestOptions) => {
    const requestHeaders = (requestOptions ? requestOptions.headers : {}) || {};

    const headers = {
        ...requestHeaders,
        'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();

    Object.keys(data).forEach(key => formData.append(key, data[key]));

    const result = await axios({
        method: 'post',
        data: formData,
        url,
        responseType: 'json',
        ...requestOptions,
        headers: getHeaders(headers),
    });

    return result.data;
};

export const getSearchString = (obj) => Object.keys(obj).reduce((params, key) => {
    params.set(key, obj[key]);
    return params;
}, new URLSearchParams()).toString();

export default Api;
export const baseApi = new Api(baseAuthService);
