import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { useQuery } from 'hooks/useQuery';
import { AccountEmailHeader } from 'library/AccountEmailHeader';
import { NotificationsList } from 'library/NotificationsList';
import { SortFilterModal } from 'library/TileList';

import { Header, defaultSearchValue } from './Header';
import { List } from './List';
import { loadPublicStores } from './Controller';
import { AddStoreModal } from './AddStoreModal';
import { AddStoreForm } from './AddStoreForm';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';
import { isAdmin } from 'services/Auth';
import { downloadStoresFile, createPreInstallStore } from 'services/Stores';
import { getUserAccountDetails } from 'services/Account';
import { formDataValidator } from './helpers/formDataValidator';

import {
    defaultSorting,
    MOBILE_USERS_SORT_OPTIONS,
    accountEmailVariants,
} from './constants';

import './StatusPage.scss';
import { AX_ERRORS } from 'constants/errors';

const loadStores = async (uuid, setStores, setIsLoading, setIsCorpUser, isAdmin) => {
    setIsLoading(true);

    try {
        const { stores, isCorpUser } = await loadPublicStores(uuid, isAdmin);

        setStores(stores);
        setIsCorpUser(!uuid && isCorpUser === 1);
    } catch (err) {
        addErrorNotification('common__error--internal-server');
        setStores([]);
    }

    setIsLoading(false);
};

const loadStoresFile = async ({ searchValue, sorting, setIsFileLoading, uuid }) => {
    setIsFileLoading(true);

    try {
        await downloadStoresFile({ searchValue, sorting, uuid });
        addSuccessNotification('stores__success__stores-downloaded');
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsFileLoading(false);
};

const addStore = async (uuid, data) => {
    try {
        const { User_EmailAddress: accountEmail = '' } = await getUserAccountDetails(uuid, true);
        const {
            axCustomerAccountNumber,
            brandLetter,
            brandId,
            countryId,
            providerId,
            storeName,
            storeNumber,
            nitroSubscriptionID,
            nexeoTierID
        } = data;
        let result;
        const payload = {
            storeBrandID: brandId,
            storeName,
            storeNumber,
            storeCountryID: countryId,
            storeAXCustomerAccount: axCustomerAccountNumber,
            vaioProviderId: providerId,
            uuid,
            accountEmail,
            brandLetter,
            nitroSubscriptionID,
            nexeoTierID
        };

        result = await createPreInstallStore(payload);
        if (!result.status) {
            return result;
        }
        addSuccessNotification('admin__stores__add-success__notification');
        return result = {
            status: true
        }
    } catch (err) {
        addErrorNotification(err.message);
    }
}

const StatusPageComponent = ({ user }) => {
    const [stores, setStores] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRendering, setIsRendering] = useState(false);
    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const [isCorpUser, setIsCorpUser] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isAddStoreOptionsLoading, setIsAddStoreOptionsLoading] = useState(false);
    const [isAddStoreFormSubmitEnabled, setIsAddStoreFormSubmitEnabled] = useState(false);
    const [isAddStoreModalShown, setIsAddStoreModalShown] = useState(false);
    const [newStoreFormData, setNewStoreFormData] = useState(null);
    const [newStoreFormErrors, setNewStoreFormErrors] = useState({});
    const [genericFormError, setGenericFormError] = useState(null);
    const [sorting, setSorting] = useState(defaultSorting);
    const [isSortModalOpen, setSortModalOpen] = useState(false);

    const onSortModalOpen = useCallback(() => setSortModalOpen(true), []);
    const onSortModalClose = useCallback(() => setSortModalOpen(false), []);

    const query = useQuery();
    const uuid = useMemo(() => query.get('uuid') || null, [query]);
    const isAdminUser = useMemo(() => isAdmin(), []);

    const { t } = useTranslation();

    const onAddNewStore  = useCallback(() => {
        setIsAddStoreModalShown(true);
    }, []);

    useEffect(() => {
        loadStores(uuid, setStores, setIsLoading, setIsCorpUser, user.admin);
    }, [uuid, setStores, setIsLoading, setIsCorpUser]);

    const onDownloadFile = useCallback( async () => {
        await loadStoresFile({ searchValue, sorting, setIsFileLoading, uuid });
    }, [searchValue, sorting, uuid]);

    const onAddStoreModalHide = useCallback(() => {
        setIsAddStoreModalShown(false);
        setNewStoreFormErrors({});
        setGenericFormError();
    }, []);

    const onAddStore = useCallback(async () => {
        const errors = formDataValidator(newStoreFormData);

        if (errors.status) {
            setNewStoreFormErrors(errors);
            return;
        }

        setNewStoreFormErrors({});
        setGenericFormError();

        const result = await addStore(uuid, newStoreFormData);
        if (!result.status) {
            setIsAddStoreModalShown(true);
            if (result.code && result.code === AX_ERRORS.INVALID_AX_ACC) {
                setGenericFormError(t('stores__add_store__form__ax-account-error'));
            } else {
                setGenericFormError(t('stores__add_store__form__generic-error'));
            }
            return;
        }
        setGenericFormError();
        setIsAddStoreModalShown(false);
        await loadStores(uuid, setStores, setIsLoading, setIsCorpUser, user.admin);
    }, [newStoreFormData, uuid, loadStores])

    const addStoreActions = useMemo(
        () =>
            isAddStoreOptionsLoading
                ? []
                : [
                      {
                          children: t('common__cancel'),
                          onClick: onAddStoreModalHide,
                      },
                      {
                          children: t('stores__add_store__form__add-store'),
                          variants: ['submit'],
                          disabled: !isAddStoreFormSubmitEnabled,
                          onClick: onAddStore,
                      },
                  ],
        [isAddStoreOptionsLoading, isAddStoreFormSubmitEnabled, onAddStore, onAddStoreModalHide],
    );

    const onFiltersAndSortReset = useCallback(() => {
        setSorting(defaultSorting);
    }, []);

    return (
        <>
            <NotificationsList />
            <AccountEmailHeader variants={accountEmailVariants} />
            <div className="hme-stores-status--public">
                <Header
                    uuid={uuid}
                    isCorpUser={isCorpUser}
                    isFileLoading={isFileLoading}
                    isStoresAvailable={stores.length > 0}
                    onSearch={setSearchValue}
                    onAddNewStore={onAddNewStore}
                    onDownloadFile={onDownloadFile}
                    onModalOpen={onSortModalOpen}
                />
                <List
                    stores={stores}
                    sorting={sorting}
                    isCorpUser={isCorpUser}
                    isLoading={isLoading || isRendering}
                    searchValue={searchValue}
                    onRenderingChange={setIsRendering}
                    onSorting={setSorting}
                    isAdminUser={isAdminUser}
                />
            </div>
            <AddStoreModal
                show={isAddStoreModalShown}
                onHide={onAddStoreModalHide}
                actions={addStoreActions}
                className={isAddStoreOptionsLoading ? 'hme-modal-add-store__loading' : ''}
            >
                <AddStoreForm
                    formErrors={newStoreFormErrors}
                    onFormError={setNewStoreFormErrors}
                    genericFormError={genericFormError}
                    onChange={setNewStoreFormData}
                    onOptionsLoading={setIsAddStoreOptionsLoading}
                    onFormSubmitEnabled={setIsAddStoreFormSubmitEnabled}
                />
            </AddStoreModal>

            <SortFilterModal
                isSortModalOpen={isSortModalOpen}
                sortOptions={MOBILE_USERS_SORT_OPTIONS}
                sortSelection={sorting}
                onSortChange={setSorting}
                onFiltersAndSortReset={onFiltersAndSortReset}
                onSortModalClose={onSortModalClose}
            />
        </>
    );
};

export const StatusPage = compose(withHMELayout())(StatusPageComponent);
