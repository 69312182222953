import React from 'react';
import PropTypes from 'prop-types';
import { cva } from 'cva';

import './ErrorList.scss';

const error = cva('hme-error-list', {
    variants: {
        variant: {
            error: 'hme-error-list--error'
        }
    },
    defaultVariants: {
        variant: 'error'
    }
});

export const ErrorList = ({ id, errors, className, variant }) => {
    const errorsToRender = errors?.filter(Boolean);
    if (!errorsToRender?.length) return null;

    return (
        <ul id={id} className={error({ variant, className })}>
            {errorsToRender.map((e) => (
                <li key={e}>{e}</li>
            ))}
        </ul>
    );
};

ErrorList.propTypes = {
    id: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    variant: PropTypes.oneOf(['error'])
};
