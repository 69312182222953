import * as React from 'react';
import classNames from 'classnames';

import './Input.scss';

/**
 * @description accessible component that represents an input element.
 */
export const Input = React.forwardRef(({ className, type, ...props }, ref) => {
    return (
        <input
            type={type}
            className={classNames('hme-form-input', className)}
            ref={ref}
            {...props}
        />
    );
});
Input.displayName = 'Input';
