export const defaultSorting = {
    brandText: 1
};

export const MOBILE_USERS_SORT_OPTIONS = [
    {
        label: 'stores__sorting-category-title__by-store-information',
        options: [
            {
                label: 'settings_snapshots__tiles__sorting__brand__asc',
                value: { brandText: 1 }
            },
            {
                label: 'settings_snapshots__tiles__sorting__brand__desc',
                value: { brandText: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-store-number__asc',
                value: { Store_Number: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-store-number__desc',
                value: { Store_Number: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-store-name__asc',
                value: { Store_Name: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-store-name__desc',
                value: { Store_Name: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-store-address__asc',
                value: { Store_AddressLine1: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-store-address__desc',
                value: { Store_AddressLine1: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-city-state__asc',
                value: { cityString: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-city-state__desc',
                value: { cityString: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-leaderboard__asc',
                value: { Group_Name: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-leaderboard__desc',
                value: { Group_Name: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-report__asc',
                value: { GroupName: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-report__desc',
                value: { GroupName: -1 }
            }
        ]
    },
    {
        label: 'stores__list-header__device-information',
        options: [
            {
                label: 'stores__sorting-option-label__by-serial-number__asc',
                value: { SerialNumber: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-serial-number__desc',
                value: { SerialNumber: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-product-id__asc',
                value: { DeviceProductID: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-product-id__desc',
                value: { DeviceProductID: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-version-number__asc',
                value: { Version: -1 }
            },
            {
                label: 'stores__sorting-option-label__by-version-number__desc',
                value: { Version: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-status__asc',
                value: { Status: 1 }
            },
            {
                label: 'stores__sorting-option-label__by-status__desc',
                value: { Status: -1 }
            }
        ]
    }
];
