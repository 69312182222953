import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { useTrigger } from 'hooks/useTrigger';
import { useStatusQueryParams } from 'hooks/useStatusQueryParams';
import { useLoadCountries } from 'hooks/useLoadCountries';
import { Paginate } from 'library/Paginate';
import { NotificationsList } from 'library/NotificationsList';
import { getFilters, OverrideCountryForDevice } from 'services/Device';
import { getSystems } from 'services/Systems';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { sendAdminDevicesReport } from 'services/Systems';
import { formatAppliedFilters } from 'helpers/formatAppliedFilters';
import { Header } from './Header';
import { Footer } from './Footer';
import { SystemsList } from './SystemsList';

import './Systems.css';

const deviceTypeAdaptor = (deviceTypesFilters = []) => {
    return deviceTypesFilters.map((deviceTypeFilter) => ({
        ...deviceTypeFilter,
        text: deviceTypeFilter.displayName
    }));
};

const filtersAdaptor = (filters) => {
    return {
        ...filters,
        Device_Type: deviceTypeAdaptor(filters.Device_Type)
    };
};

const INIT_SEARCH_PARAMS = { selectValue: 'Device_SerialNumber', searchValue: '' };
const INIT_SORT_SELETION = { Brand_Name: 1 };
const INIT_PAGE_NUMBER = 0;
const INIT_ITEMS_PER_PAGE = 10;
const SEARCH_DEBOUNCE_TIMEOUT = 1000;
const SEARCH_MIN_SYMBOLS_AUTOSEARCH = 3;

const loadSystems = async ({
    setIsSystemsLoading,
    setSystems,
    pageNumber = 0,
    itemsPerPage,
    sortSelection,
    filters = {},
    appliedFilters = {},
    searchParams = {},
    onDone,
    setItemsTotal,
}) => {
    try {
        setIsSystemsLoading(true);

        const { selectValue = '', searchValue = '' } = searchParams;
        const [sortColumn] = Object.keys(sortSelection);
        const sortingType = sortSelection && sortSelection[sortColumn];

        const systems = await getSystems({
            pageNumber: pageNumber + 1,
            itemsPerPage,
            sortColumn,
            sortingType,
            searchField: selectValue,
            search: searchValue,
            appliedFilters: formatAppliedFilters(filters, appliedFilters),
            template: 'systemsPageTemplate'
        });

        setSystems(systems.devices || []);
        setItemsTotal(systems.total || 0);
    } catch (e) {
        setSystems([]);
        setItemsTotal(0);
    }

    setIsSystemsLoading(false);

    // trigger callback for debounced executions
    onDone && onDone();
};

const loadSystemsDebounced = debounce(loadSystems, SEARCH_DEBOUNCE_TIMEOUT);

const loadFilters = async ({ setIsFiltersLoading, setFilters }) => {
    try {
        setIsFiltersLoading(true);

        const filters = await getFilters();

        setFilters(filtersAdaptor(filters));
    } catch (e) {
        addErrorNotification('common__error--internal-server');
        setFilters({});
    }

    setIsFiltersLoading(false);
};

const sendReport = async (
    {
        sortSelection,
        filters = {},
        appliedFilters = {},
        searchParams = {},
        setIsEmailLinkDisabled,
        t
    }) => {
    try {
        setIsEmailLinkDisabled(true);

        const { selectValue = '', searchValue = '' } = searchParams;
        const [sortColumn] = Object.keys(sortSelection);
        const sortingType = sortSelection && sortSelection[sortColumn];

        const email = await sendAdminDevicesReport({
            sortColumn,
            sortingType,
            searchField: selectValue,
            search: searchValue,
            appliedFilters: formatAppliedFilters(filters, appliedFilters)
        });
        addSuccessNotification(t('admin__systems__notification--report-successfully-sent', { email }));
    } catch (e) {
        addErrorNotification('common__error--internal-server');
    }
    setIsEmailLinkDisabled(false);
}

const overrideCountry = async ({ setIsOverridingCountry, countryId, deviceUID    }) => {
    try {
        setIsOverridingCountry(true);

        await OverrideCountryForDevice(countryId, deviceUID);

        // error message is used for showing the warning icon in the notification
        addErrorNotification('admin_systems__notification__override-country--warning', {
            className: 'hme-systems-override-country-warning-notification'
        });
    } catch (e) {
        addErrorNotification('common__error--internal-server');
    }

    setIsOverridingCountry(false);
};

export const Systems = () => {
    const [systems, setSystems] = useState([]);
    const [filters, setFilters] = useState({});
    const [isSystemsLoading, setIsSystemsLoading] = useState(false);
    const [isFiltersLoading, setIsFiltersLoading] = useState(false);
    const [isOverridingCountry, setIsOverridingCountry] = useState(false);

    const {
        page,
        perPage,
        searchParams,
        appliedFilters,
        sortSelection,
        setPagination,
        setSearchParams,
        setSortSelection,
        setAppliedFilters
    } = useStatusQueryParams({
        page: INIT_PAGE_NUMBER,
        perPage: INIT_ITEMS_PER_PAGE,
        searchParams: INIT_SEARCH_PARAMS,
        sortSelection: INIT_SORT_SELETION,
        appliedFilters: {},
    });

    const [itemsTotal, setItemsTotal] = useState(0);
    const [filtersCount, setFiltersCount] = useState(0);
    const [isEmailLinkDisabled, setIsEmailLinkDisabled] = useState(false);
    const searchRef = useRef(null);
    const resetFiltersTrigger = useTrigger();
    const { t } = useTranslation();

    const { countries, isCountriesLoading } = useLoadCountries();

    const onPaginationChange = useCallback(
        ({ page = 0, recordsPerPage = 10 } = {}) => {
            setPagination({ page, perPage: recordsPerPage });

            loadSystems({
                setIsSystemsLoading,
                setSystems,
                pageNumber: page,
                itemsPerPage: recordsPerPage,
                sortSelection,
                searchParams,
                filters,
                appliedFilters,
                setItemsTotal,
            });
        },
        [
            sortSelection,
            searchParams,
            filters,
            appliedFilters,
        ],
    );

    const onSortChange = useCallback(
        (newSortData) => {
            setSortSelection(newSortData);
            setPagination({ page: page, perPage });
            loadSystems({
                setIsSystemsLoading,
                setSystems,
                itemsPerPage: perPage,
                sortSelection: newSortData,
                searchParams,
                filters,
                appliedFilters,
                setItemsTotal,
            });
        },
        [
            setSortSelection,
            page,
            perPage,
            filters,
            appliedFilters,
            searchParams,
        ],
    );

    const focusSearch = useCallback(() => {
        if (searchRef && searchRef.current) {
            searchRef.current.focus();
        }
    }, []);

    const onSearchDone = useCallback(() => {
        focusSearch();
        setPagination({ page: INIT_PAGE_NUMBER, perPage: perPage });
    }, [perPage]);

    const onSearch = useCallback(
        (newSearchParams) => {
            loadSystemsDebounced.cancel();
            setPagination({ page: INIT_PAGE_NUMBER, perPage })
            loadSystems({
                setIsSystemsLoading,
                setSystems,
                itemsPerPage: perPage,
                sortSelection,
                searchParams: newSearchParams,
                filters,
                appliedFilters,
                onDone: onSearchDone,
                setItemsTotal,
            });
        },
        [
            perPage,
            sortSelection,
            filters,
            appliedFilters,
            onSearchDone,
        ],
    );

    const onSearchParamsChange = useCallback(
        (newSearchParams) => {
            setSearchParams(newSearchParams);

            if (!newSearchParams.searchValue) {
                focusSearch();
            }

            loadSystemsDebounced.cancel();

            const { length: searchValueLength = 0 } = newSearchParams.searchValue;

            if (searchValueLength === 0 || searchValueLength >= SEARCH_MIN_SYMBOLS_AUTOSEARCH) {
                setPagination({ page: INIT_PAGE_NUMBER, perPage });

                loadSystemsDebounced({
                    setIsSystemsLoading,
                    setSystems,
                    itemsPerPage: perPage,
                    sortSelection,
                    searchParams: newSearchParams,
                    appliedFilters,
                    onDone: onSearchDone,
                    setItemsTotal
                });
            }
        },
        [
            perPage,
            sortSelection,
            appliedFilters,
            onSearchDone,
        ],
    );

    const onFiltersChange = useCallback(
        ({ Brand_Name, Device_Type, LaneConfig_Name, Status }) => {
            const newAppliedFilters = {
                Brand_Name,
                Device_Type,
                LaneConfig_Name,
                Status
            };

            setAppliedFilters(newAppliedFilters, filters);

            setPagination({ page: INIT_PAGE_NUMBER, perPage });

            loadSystems({
                setIsSystemsLoading,
                setSystems,
                itemsPerPage: perPage,
                sortSelection,
                searchParams,
                filters,
                appliedFilters: newAppliedFilters,
                setItemsTotal,
            });
        },
        [perPage, sortSelection, searchParams, filters, setAppliedFilters],
    );

    useEffect(() => {
        loadFilters({ setIsFiltersLoading, setFilters });
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length) {
            loadSystems({
                setIsSystemsLoading,
                setSystems,
                pageNumber: page,
                itemsPerPage: perPage,
                sortSelection,
                searchParams,
                filters,
                appliedFilters,
                setItemsTotal,
            });
        }
    }, [filters]);

    const pageSizes = [10, 20, 50, 100];

    const shouldFooterBeShown = useMemo(
        () => !!(systems.length && itemsTotal > pageSizes[0]),
        [systems, itemsTotal, pageSizes],
    );

    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
        setAppliedFilters({}, filters);

        loadSystems({
            setIsSystemsLoading,
            setSystems,
            pageNumber: page,
            itemsPerPage: perPage,
            sortSelection,
            searchParams,
            setItemsTotal,
        });
    }, [
        systems,
        setAppliedFilters,
        page,
        perPage,
        sortSelection,
        searchParams,
        resetFiltersTrigger
    ]);

    const onReportSend = useCallback(() => {
        sendReport({
            sortSelection,
            searchParams,
            filters,
            appliedFilters,
            setIsEmailLinkDisabled,
            t,
        });
    }, [sortSelection, searchParams, filters, appliedFilters, t]);

    const onOverrideCountry = useCallback(({ countryId, deviceUID }) => {
        overrideCountry({ setIsOverridingCountry, countryId, deviceUID });
    }, []);

    return (
        <div className={classNames('hme-page-component', 'hme-systems-wrapper')}>
            <div className="hme-components hme-systems">
                <NotificationsList />
                <Header
                    searchParams={searchParams}
                    onSearchParamsChange={onSearchParamsChange}
                    onSearch={onSearch}
                    searchRef={searchRef}
                    filtersCount={filtersCount}
                    isEmailLinkDisabled={isEmailLinkDisabled}
                    onFiltersReset={onFiltersReset}
                    onReportSend={onReportSend}
                />
                <SystemsList
                    systems={systems}
                    countries={countries}
                    selectedFilters={appliedFilters}
                    onOverrideCountry={onOverrideCountry}
                    isLoading={isSystemsLoading || isFiltersLoading || isCountriesLoading || isOverridingCountry}
                    onSortChange={onSortChange}
                    sortSelection={sortSelection}
                    filters={filters}
                    resetFiltersTrigger={resetFiltersTrigger}
                    onFiltersChange={onFiltersChange}
                    onFiltersCountChange={setFiltersCount}
                />
                {shouldFooterBeShown && (
                    <Footer>
                        <Paginate
                            className="hme-systems__paginate"
                            page={page}
                            recordsPerPage={perPage}
                            pageSizes={pageSizes}
                            total={itemsTotal}
                            onChange={onPaginationChange}
                            hideSinglePage
                        />
                    </Footer>
                )}
            </div>
        </div>
    );
};
