import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { equals, isEmpty } from 'ramda';
import { DateLib } from '@hme-cloud/utility-common';

import { trimValues } from 'helpers';
import { Label } from 'library/Label';
import { Toggle } from 'library/Toggle';
import { Select } from 'library/Select';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { getCurrentUTCTimestamp } from 'services/Date';
import { CenterLoader } from 'components/Common/CenterLoader';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { ADMIN_ROUTES } from 'constants/routes';
import { getTokenExpirationType, expirationTypes } from 'constants/token';

import { Footer } from '../Footer';
import { Section } from '../../Common/Section';
import { Input } from '../../Common/Input';
import { InputLabelWithTip } from '../../Common/InputLabelWithTip';
import { RenewTokenModal } from '../../Common/RenewTokenModal';
import { CopyTokenModal } from '../../Common/CopyTokenModal';
import { ProviderField } from '../DetailsSection/ProviderSection/ProviderForm';
import { getAPITokenByUID, renewToken } from '../../Controller';
import { renewTokenTypes } from '../../constants';
import {
    DEFAULT_TOKEN_MANAGER,
    EMAIL,
    FORM_DEFAULTS,
    HME_API_TYPES_ENUM,
    REQUIRED_INPUTS,
    TOKEN_USER_TYPES_EDIT,
    VAIO_DEFAULTS,
    createToken,
    formDataValidator,
    isApiTypeDXS,
    prepareISODateString,
    tokenManagementBaseUrl
} from '../helpers';
import { usePartnerEmails } from 'library/PartnerEmailDropdown/hooks/usePartnerEmails';
import { PartnerEmailDropdown } from 'library/PartnerEmailDropdown/PartnerEmailDropdown';
import { RadioGroup } from 'library/RadioGroup';
import { keys } from 'underscore';
import { AccountsSection } from '../DXS/AccountsSection';
import { isApiTypeVAIO } from '../helpers/isPartnerVAIO';

const tokenExpirationValues = [{ text: '6 months', value: 6 }];

const DEFAULT_ERRORS = {};

const isEditPartnerDisabled = true;

const API_TYPES = {
    VAIO: 'api-token-management__form--hme-apis-vaio-value',
    DXS: 'api-token-management__form--hme-apis-dxs-value'
};

const getApiRadioOptions = (t) => keys(API_TYPES).map((value) => ({ text: t(API_TYPES[value]), value }));


export const EditTokenForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tokenUID = searchParams.get('tokenUID');

    const {
        email: partnerEmail,
        matchedEmails: matchedPartnerEmails,
        handleEmailChange: fetchPartnerEmails,
        setEmail: setPartnerEmail,
        isLoading: isPartnerLoading
    } = usePartnerEmails();

    const [formErrors, setFormErrors] = useState(DEFAULT_ERRORS);
    const [existingFormValues, setExistingFormValues] = useState(DEFAULT_ERRORS);
    const [formValues, setFormValues] = useState(FORM_DEFAULTS[HME_API_TYPES_ENUM.VAIO]);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isRenewTokenInProgress, setIsRenewTokenInProgress] = useState(false);

    const [isRenewTokenShown, setIsRenewTokenShown] = useState(false);

    const [isCopyTokenModalShown, setIsCopyTokenModalShown] = useState(false);

    // true condition == error
    const formValidations = () => ({
        tokenManager: !EMAIL.test(formValues.tokenManager.email.trim()),
        email2: formValues.email2.trim() && !EMAIL.test(formValues.email2.trim()),
        email3: formValues.email3.trim() && !EMAIL.test(formValues.email3.trim())
    });

    const isTokenExpired = useMemo(() => {
        return getTokenExpirationType(formValues.expiryDate) === expirationTypes.expired;
    }, [formValues.expiryDate]);

    const onChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const onProviderChange = useCallback((vaioProvider) => {
        const { id, name, audioMode, locationType } = vaioProvider || {};

        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            vaioProvider: vaioProvider ? { id, name, audioMode, locationType } : ''
        }));
    }, []);

    const onTokenManagerSelect = useCallback((newTokenManager) => {
        setPartnerEmail(newTokenManager.email);
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            tokenManager: newTokenManager
        }));
    }, []);

    const handlePartnerEmailChange = useCallback(async (newEmail) => {
        try {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                tokenManager: { ...DEFAULT_TOKEN_MANAGER, email: newEmail },
                vaioProvider: VAIO_DEFAULTS.vaioProvider
            }));
            await fetchPartnerEmails(newEmail);
        } catch (_err) {
            // do nothing when request is programmatically canceled
        }
    }, []);

    const onAccountSelection = useCallback((account) => {
        setFormValues(({ cloudAccounts = [], ...prevFormValues }) => ({
            ...prevFormValues,
            cloudAccounts: [...cloudAccounts, account]
        }));
    });

    const onAccountRemoval = useCallback((userID) => {
        setFormValues(({ cloudAccounts = [], ...prevFormValues }) => ({
            ...prevFormValues,
            cloudAccounts: cloudAccounts.filter(({ User_ID: accountID } = {}) => accountID !== userID)
        }));
    });

    useEffect(() => {
        if (!tokenUID) {
            return;
        }

        const getToken = async () => {
            setIsLoading(true);

            const token = await getAPITokenByUID(tokenUID, navigate, t);

            if (token.tokenManager) {
                const [fecthedInitialPartnerEmail] = await fetchPartnerEmails(token.tokenManager.email);
                token.tokenManager = fecthedInitialPartnerEmail;
            }

            setExistingFormValues(token);
            setFormValues(token);
            setIsLoading(false);
        };
        getToken();
    }, [tokenUID]);

    useEffect(() => {
        let enableSave = true;
        const { cloudAccounts: existingCloudAccounts = [], ...restExistingFormValues } = existingFormValues || {};
        const { cloudAccounts: cloudAccounts = [], ...restFormValues } = formValues || {};

        const extractUserID = (accounts = []) => accounts.map(({ User_ID: userID } = {}) => Number(userID)).filter(Boolean).sort();
        // check if object formValues has same values as object existingFormValues
        const isSame = equals(restFormValues, restExistingFormValues)
        && equals(extractUserID(cloudAccounts), extractUserID(existingCloudAccounts));

        for (const input of REQUIRED_INPUTS[formValues.apiType]) {
            if (isEmpty(formValues[input]) || (Array.isArray(formValues[input]) && !formValues[input].length)) {
                enableSave = false;
                break;
            }
        }

        setSaveEnabled(isSame ? false : enableSave);
    }, [formValues]);

    useEffect(() => {
        if (isApiTypeVAIO(formValues.apiType) &&
            !formValues.vaioProvider &&
            equals(formValues.tokenManager, existingFormValues.tokenManager)) {
            setFormValues((prevFormValues) => ({ ...prevFormValues, vaioProvider: existingFormValues.vaioProvider }));
        }
    }, [formValues.vaioProvider, formValues.tokenManager, existingFormValues.tokenManager, existingFormValues.vaioProvider]);

    const onEditToken = useCallback(async () => {
        const errors = formDataValidator(formValidations(), t);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setSaveEnabled(false);
            return;
        }
        // Go through values in formValues and trim beginning and ending whitespace
        const trimmedFormValues = trimValues(formValues, Object.keys(formValues));

        await createToken(trimmedFormValues, navigate, setIsLoading, tokenUID, t);
    }, [formValues, tokenUID]);

    const closeCopyPopup = useCallback(() => {
        navigate(ADMIN_ROUTES.tokenManagement);
    }, [navigate]);

    const openRenewTokenModal = useCallback(() => {
        setIsRenewTokenShown(true);
    }, []);

    const closeRenewTokenModal = useCallback(() => {
        setIsRenewTokenShown(false);
    }, []);

    const onRenewToken = async (tokenType, { specificDate }) => {
        setIsRenewTokenInProgress(true);

        const tokenToSend = {
            tokenUID,
            disablePreviousToken: false,
            actionType: tokenType,
            prevTokenExpirationDate: prepareISODateString()
        };

        switch (tokenType) {
            case renewTokenTypes.ORIGINAL_EXP_DATE:
                tokenToSend.prevTokenExpirationDate = formValues.expiryDate;
                break;
            case renewTokenTypes.TODAY_EXP_DATE:
                tokenToSend.disablePreviousToken = true;
                tokenToSend.prevTokenExpirationDate = prepareISODateString();
                break;
            case renewTokenTypes.SPECIFIC_EXP_DATE:
                tokenToSend.prevTokenExpirationDate = specificDate.format(DateLib.FORMAT_TYPES.YEAR_MONTH_DATE + ' 00:00:00.000');
                break;
            default:
                break;
        }

        try {
            const { status, token: newToken } = await renewToken(tokenToSend);

            if (!status) return;

            setFormValues((currentFormValues) => ({
                ...currentFormValues,
                lastRenewedDate: tokenToSend.prevTokenExpirationDate,
                generatedToken: newToken.token.generatedToken
            }));
            closeRenewTokenModal();
            setIsCopyTokenModalShown(true);
            addSuccessNotification('api-token-management__form--token-renew-success', { autoClose: 5000 });
        } catch (err) {
            addErrorNotification('api-token-management__form--token-renew-failed', { autoClose: 5000 });
        } finally {
            setIsRenewTokenInProgress(false);
        }
    };

    const onCancel = useCallback(() => {
        navigate(tokenManagementBaseUrl);
    }, [navigate]);

    const isRenewTokenDisabled = useMemo(() => {
        const { apiType, vaioProvider, cloudAccounts = [] } = formValues;
        if (
            !equals(formValues, existingFormValues) ||
            (isApiTypeVAIO(apiType) && !vaioProvider) ||
            (isApiTypeDXS(apiType) && !cloudAccounts.length)
        ) {
            return true;
        }

        if (!formValues.lastRenewedDate) {
            return false;
        }

        const lastRenewedDate = new Date(formValues.lastRenewedDate).getTime();
        const newDate = new Date(getCurrentUTCTimestamp()).getTime();

        return newDate - lastRenewedDate < 900000;
    }, [formValues.lastRenewedDate, formValues.vaioProvider, formValues.cloudAccounts, formValues, existingFormValues]);

    return isLoading ? (
        <CenterLoader>{t('common__loading')}</CenterLoader>
    ) : (
        <>
            <div className="hme-page-component api-management-form-page">
                <div className="api-management-form-page-header">
                    <Title>{t('api-token-management__form-edit__page-title')}</Title>

                    <Button onClick={openRenewTokenModal} disabled={isRenewTokenDisabled || saveEnabled}>
                        {t('api-token-management__renew-token')}
                    </Button>
                </div>

                <div className="api-management-form-page-sections">
                    <Section title={t('common__general')}>
                        <Input
                            label={t('api-token-management__form--token-name')}
                            name="tokenName"
                            value={formValues.tokenName}
                            maxLength={32}
                            isDisabled
                        />

                        <div>
                            <Label>{t('api-token-management__form--token-expiration')}</Label>
                            <Select
                                value={formValues.tokenExpiration}
                                placeholder={`-${t('new-vaio-provider__form--token-expiration-placeholder')}-`}
                                isDisabled
                                isRequired
                            >
                                {tokenExpirationValues}
                            </Select>
                        </div>

                        <div>
                            <Label className="vaio-provider-form-label">{t('api-token-management__form--hme-apis')}</Label>
                            <RadioGroup
                                key="apiType"
                                items={getApiRadioOptions(t)}
                                value={formValues.apiType}
                                checked={true}
                                disabled
                            />
                        </div>
                    </Section>
                    <Section title={t('api-token-management__section__title--email')}>
                        <Toggle label={t('api-token-management__form--token-user')} value={formValues.tokenUser} disabled>
                            {TOKEN_USER_TYPES_EDIT}
                        </Toggle>

                        <div>
                            <PartnerEmailDropdown
                                email={partnerEmail}
                                partnerEmails={matchedPartnerEmails}
                                onSelect={!isEditPartnerDisabled ? onTokenManagerSelect : null}
                                onChange={!isEditPartnerDisabled ? handlePartnerEmailChange : null}
                                isLoading={isPartnerLoading}
                                error={formErrors.tokenManager}
                                label={
                                    <InputLabelWithTip
                                        title={t('api-token-management__form--token-manager-email')}
                                        tipText={t('api-token-management__form--edit-token-email-desctipion')}
                                        clickable={false}
                                    />
                                }
                                isDisabled={isEditPartnerDisabled}
                            />
                        </div>

                        <Input
                            label={t('api-token-management__form--email2')}
                            name="email2"
                            value={formValues.email2}
                            maxLength={50}
                            onChange={onChange}
                            error={formErrors.email2}
                            universalOnChange
                        />

                        <Input
                            label={t('api-token-management__form--email3')}
                            name="email3"
                            value={formValues.email3}
                            maxLength={50}
                            onChange={onChange}
                            error={formErrors.email3}
                            universalOnChange
                        />
                    </Section>
                    {formValues.tokenManager?.uid && (formValues.apiType === HME_API_TYPES_ENUM.VAIO ?
                        (<Section
                            title={t('api-token-management__section__title--VAIO--detail')}
                            className="hme-api-management-section--stretch"
                        >
                            <div>
                                <ProviderField formValues={formValues} onProviderChange={onProviderChange} />
                            </div>
                        </Section>) :
                        <AccountsSection
                            isLoading={isLoading}
                            formValues={formValues}
                            onAccountSelection={onAccountSelection}
                            onAccountRemoval={onAccountRemoval}
                        />)
                    }
                </div>

                <Footer isSubmitEnabled={saveEnabled} onCancel={onCancel} onApply={onEditToken} />
            </div>

            <RenewTokenModal
                isShown={isRenewTokenShown}
                token={formValues}
                subtitleText={t(
                    isTokenExpired ?
                    'api-token-management__renew-create-token-description' : 'api-token-management__renew-create-token-description2'
                )}
                isOptionsHidden={isTokenExpired}
                isLoading={isRenewTokenInProgress}
                onSubmit={onRenewToken}
                onCancel={closeRenewTokenModal}
            />

            <CopyTokenModal
                isShown={isCopyTokenModalShown}
                tokenName={formValues.tokenName}
                tokenValue={formValues.generatedToken}
                onClose={closeCopyPopup}
            />
        </>
    );
};
