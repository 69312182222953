import React from 'react';
import { Loader } from 'library/Loader';
import classNames from 'classnames';

import { Button } from '../Button';

import './StatusButton.scss';

const companion = {
    pending: (
        <div role="status">
            <Loader className="hme-status-button__companion hme-form-button__icon" variants={['invert']} title="loading" />
        </div>
    ),
    success: (
        <div role="status">
            {/* add corresponding icon */}
        </div>
    ),
    error: (
        <div role="status">
            {/* add corresponding icon */}
        </div>
    ),
    idle: null
};

export const StatusButton = React.forwardRef(({ status = 'idle', className, children, ...props }, ref) => {
    return (
        <Button
            ref={ref}
            className={classNames('hme-status-button', className)}
            {...props}
        >
            <div>{children}</div>

            {companion[status]}
        </Button>
    );
});
StatusButton.displayName = 'StatusButton';
