export const deviceResultHeaders = [{
    text: 'common__select',
    property: 'SelectDevice',
    flex: 80,
    className: 'hme-merge-devices-device-grid-select-cell',
    headerClassName: 'hme-merge-devices-device-grid-select-header',
}, {
    text: 'common__store__number',
    property: 'Store_Number',
    className: 'hme-merge-devices-device-grid-store-number-cell',
    headerClassName: 'hme-merge-devices-device-grid-store-number-cell',
    flex: 120
}, {
    text: 'common__account',
    property: 'User_EmailAddress',
    className: 'hme-merge-devices-device-grid-account-cell',
    headerClassName: 'hme-merge-devices-device-grid-account-cell',
    flex: 150
}, {
    text: 'common__store__name',
    property: 'Store_Name',
    className: 'hme-merge-devices-device-grid-store-name-cell',
    headerClassName: 'hme-merge-devices-device-grid-store-name-cell',
    flex: 170
}, {
    text: 'common__system',
    property: 'DeviceType_Name',
    className: 'hme-merge-devices-device-grid-device-type-cell',
    headerClassName: 'hme-merge-devices-device-grid-device-type-cell',
    flex: 125
}, {
    text: 'common__device__serial-number',
    property: 'Device_SerialNumber',
    className: 'hme-merge-devices-device-grid-system-serial-number-cell',
    headerClassName: 'hme-merge-devices-device-grid-system-serial-number-cell',
    flex: 125
}, {
    text: 'common__device__product-id',
    property: 'Device_Product_ID',
    className: 'hme-merge-devices-device-grid-system-serial-number-cell',
    headerClassName: 'hme-merge-devices-device-grid-system-serial-number-cell',
    flex: 160
}, {
    text: 'common__brand',
    property: 'Brand_Name',
    className: 'hme-merge-devices-device-grid-brand-cell',
    headerClassName: 'hme-merge-devices-device-grid-brand-cell',
    flex: 125
}, {
    text: 'common__status', // Device is active
    property: 'Device_Status',
    className: 'hme-filter-input-view-store-status-cell',
    headerClassName: 'hme-filter-input-view-store-status-cell',
    flex: 110
}];

export const storeResultHeaders = [{
    text: 'common__store__number',
    property: 'Store_Number',
    className: 'hme-merge-devices-store-number-cell',
    headerClassName: 'hme-merge-devices-store-number-cell',
    flex: 100
}, {
    text: 'common__account',
    property: 'User_EmailAddress',
    className: 'hme-merge-devices-account-cell',
    headerClassName: 'hme-merge-devices-account-cell',
    flex: 248
}, {
    text: 'common__store__name',
    property: 'Store_Name',
    className: 'hme-merge-devices-store-name-cell',
    headerClassName: 'hme-merge-devices-store-name-cell',
    flex: 136
}, {
    text: 'common__system',
    property: 'DeviceType_Name',
    className: 'hme-merge-devices-device-type-cell',
    headerClassName: 'hme-merge-devices-device-type-cell',
    flex: 136
}, {
    text: 'common__device__serial-number',
    property: 'Device_SerialNumber',
    className: 'hme-merge-devices-system-serial-number-cell',
    headerClassName: 'hme-merge-devices-system-serial-number-cell',
    flex: 100
}, {
    text: 'common__device__product-id',
    property: 'Device_Product_ID',
    className: 'hme-merge-devices-system-serial-number-cell',
    headerClassName: 'hme-merge-devices-system-serial-number-cell',
    flex: 130
}, {
    text: 'common__brand',
    property: 'Brand_Name',
    className: 'hme-merge-devices-brand-cell',
    headerClassName: 'hme-merge-devices-brand-cell',
    flex: 136
}, {
    text: 'common__status',
    property: 'Device_Status',
    className: 'hme-merge-devices-status-cell',
    headerClassName: 'hme-merge-devices-status-cell',
    flex: 100
}];

export const deviceResultMobileHeaders = [
    {
        column: [
            { text: 'common__store__number', property: 'Store_Number', },
            { text: 'common__account', property: 'User_EmailAddress', },
            { text: 'common__store__name', property: 'Store_Name', },
            { text: 'common__system', property: 'DeviceType_Name', },
            { text: 'common__device__serial-number', property: 'Device_SerialNumber', },
            { text: 'common__device__product-id', property: 'Device_Product_ID', },
            { text: 'common__brand', property: 'Brand_Name', },
            { text: 'common__status', property: 'Device_Status', }
        ],
    }
];

export const storeResultMobileHeaders = [
    {
        column: [
            { text: 'common__store__number', property: 'Store_Number', },
            { text: 'common__store__name', property: 'Store_Name', },
            { text: 'common__device__serial-number', property: 'Device_SerialNumber', },
            { text: 'common__device__product-id', property: 'Device_Product_ID', },
        ],
    },
    {
        column: [
            { text: 'common__account', property: 'User_EmailAddress', },
            { text: 'common__system', property: 'DeviceType_Name', },
            { text: 'common__brand', property: 'Brand_Name', },
            { text: 'common__status', property: 'Device_Status', },
        ],
    },
];

