import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoField } from 'library/InfoField';
import { DateLib, Constant } from '@hme-cloud/utility-common';
import { deviceTypes, deviceTypeOptions } from 'constants/device';
import { nexeoRestrictions } from 'constants/upgradeRestrictions';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';
import { findMaxVersion } from '../../../helpers';

import { ScheduleDays } from '../../../ScheduleDays';
import { initUpgradeForm } from '../../../helpers';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
const { BuildVersions } = Constant;
import './UpgradeInfoDetails.scss';

const formatInfoFields = ({ scheduledUpgrade, isInstallScheduleDateVisible = false, t }) => {
    // only upgrades for NEXEO Base Station and not IB7000 supports multiple versions
    const isNEXEOBaseStationUpgrade = scheduledUpgrade.DeviceTypeSource === deviceTypes.NEXEO &&
        scheduledUpgrade.PeripheralDeviceTypeSource !== NEXEO_PERIPHERAL_TYPES.IB7000.modelName;

    const upgradeInfoFields = [
        {
            label: 'device-upgrades__section__form--upgrade-name',
            content: scheduledUpgrade.ScheduledUpgradeName,
        },
        {
            label: 'device-upgrades__section__form--device-type',
            content: scheduledUpgrade.DeviceTypeSource,
            className: 'view-device-upgrade-info-details--device-type',
        },
        {
            label: 'device-upgrades__section__form--upgrade-from-version',
            content: isNEXEOBaseStationUpgrade
                ? scheduledUpgrade.UpgradeFromVersions.map((v) => (<div className='hme-badge-pill'>{v}</div>))
                : scheduledUpgrade.UpgradeFromVersion
        },
        {
            label: 'device-upgrades__section__form--upgrade-to-version',
            content: scheduledUpgrade.UpgradeToVersion,
        },
        {
            label: 'device-upgrades__section__form--upgrade-ran',
            content: (
                <>
                    <CalendarIcon className="input-datepicker-icon" />
                    <InfoField
                        label={t('device-upgrades__section__form--starting-on')}
                        className="view-device-upgrade-info-details--starting-on"
                    >
                        {new DateLib(scheduledUpgrade.UpgradeStartDate).formatInUTC(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT)}
                    </InfoField>
                </>
            ),
            className: 'view-device-upgrade-info-details--upgrade-ran',
        },
        {
            content: <ScheduleDays upgradeScheduleDays={scheduledUpgrade.DaysofWeek} />,
        },
        {
            label: 'device-upgrades__section__form--from',
            content: new DateLib(scheduledUpgrade.StartTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD_12_HOUR).toUpperCase(),
            className: 'view-device-upgrade-info-details--time',
        },
        {
            label: 'device-upgrades__section__form--to',
            content: new DateLib(scheduledUpgrade.EndTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD_12_HOUR).toUpperCase(),
            className: 'view-device-upgrade-info-details--time',
        },
    ];

    if (isInstallScheduleDateVisible) {
        upgradeInfoFields.push(...[{
            label: 'device-upgrades__section__form--install-runs',
            content: (
                <>
                    <CalendarIcon className="input-datepicker-icon" />
                    <InfoField
                        label={t('device-upgrades__section__form--starting-on')}
                        className="view-device-upgrade-info-details--starting-on"
                    >
                        {new DateLib(scheduledUpgrade.InstallSchedule.startDate).formatInUTC(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT)}
                    </InfoField>
                </>
            ),
            className: 'view-device-upgrade-info-details--upgrade-ran',
        },
        {
            content: <ScheduleDays upgradeScheduleDays={scheduledUpgrade.InstallSchedule.weekDays} />,
        },
        {
            label: 'device-upgrades__section__form--from',
            content: new DateLib(scheduledUpgrade.InstallSchedule.startTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD_12_HOUR).toUpperCase(),
            className: 'view-device-upgrade-info-details--time',
        },
        {
            label: 'device-upgrades__section__form--to',
            content: new DateLib(scheduledUpgrade.InstallSchedule.endTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD_12_HOUR).toUpperCase(),
            className: 'view-device-upgrade-info-details--time',
        },])
    }

    return upgradeInfoFields;
};

export const UpgradeInfoDetails = ({ scheduledUpgrade }) => {
    const { t } = useTranslation();

    const {
        DeviceTypeSource,
        PeripheralDeviceTypeSource,
        UpgradeFromVersions,
        InstallDateTime,
    } = useMemo(() => initUpgradeForm(scheduledUpgrade), [scheduledUpgrade]);
    const maxUpgradeVersion = findMaxVersion(UpgradeFromVersions);

    const isInstallScheduleDateVisible =
        UpgradeFromVersions &&
        DeviceTypeSource === deviceTypes.NEXEO &&
        PeripheralDeviceTypeSource !== NEXEO_PERIPHERAL_TYPES.IB7000.modelName &&
        BuildVersions.semverGt(maxUpgradeVersion, nexeoRestrictions.minTwoStepUpgradeVersion) &&
        (!scheduledUpgrade || // on scheduled upgrade creation
            (scheduledUpgrade && InstallDateTime?.InstallStartDate));// on scheduled upgrade with defined install date (any status)

        const infoFields = formatInfoFields({ scheduledUpgrade, isInstallScheduleDateVisible, t });

    return (
        <div className="view-device-upgrade-info-details">
            {infoFields.map(({ label = '', className = '', content = null }) => (
                <InfoField label={t(label)} className={className}>
                    {content}
                </InfoField>
            ))}
        </div>
    );
};
