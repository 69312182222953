import React, { Component } from 'react';
import classNames from 'classnames';

import './HiddenInput.css';

class HiddenInput extends Component {
  constructor() {
    super();
  }

  render() {
    const { name, label, className, onChange } = this.props;

    return (
      <div className={classNames(className, 'hme-input hidden-input')}>

        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        <input
          {...this.props}
          id={name}
          type="hidden"
          onChange={onChange}
        />
      </div>
    );
  }
}

export { HiddenInput };
