import React, { memo } from 'react';
import { GridActions } from 'library/GridActions';
import { Button } from 'library/Button';
import { useTranslation } from 'react-i18next';

const buttonVariants = ['transparent'];

export const ActionsComponent = ({ removeAccount }) => {
    const { t } = useTranslation();
    return (
        <GridActions className="hme-token-management__actions">
            <Button
                variants={buttonVariants}
                onClick={removeAccount}
            >
                {t('common__remove')}
            </Button>
        </GridActions>
    );
};

export const Actions = memo(ActionsComponent);
