import { CommonConstants } from 'Constants';
import { Config } from 'Config';
import { getDataAsync, getSearchString, postDataAsync, deleteDataAsync, putDataAsync } from 'Api';

const errorsList = {
    'requiredAccountId': 'common__error--missed-account-id',
    'noDataForGivenId': 'common__error--no-data-for-id',
    'groupIdInvalid': 'common__error--group-id-invalid',
    'groupNameEmpty': 'common__error--group-name-empty',
    'groupUpdationFailed': 'add-group__error--group-update-failed',
    'groupAlreadyExist': 'add-group__error--group-exist',
    'noDataForGivenName': 'common__error--no-data-for-name',
    'wrong_groupname': 'manage-hierarchy__error--wrong-group-name'
};

export const getDistributorGroups = async ({ accountUuid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccountGroups}`;

    const params = {
        auuid: accountUuid,
        zoomStoresOnly: false
    };

    const search = getSearchString(params);

    const { data, status, key } = await getDataAsync(`${url}?${search}`);

    if (!status && key !== 'noDataForGivenId') {
        throw new Error('common__error--internal-server');
    }

    return data;
};

export const getGroups = async (uuid, searchParams = {}) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getGroupHierarchyTree}`;

    const params = {
        zoomStoresOnly: false,
        ...searchParams
    };

    if (uuid) {
        params.uuid = uuid;
    }

    const search = getSearchString(params);

    const { data = [], status, key } = await getDataAsync(`${url}?${search}`);

    if (!status && key !== 'noDataForGivenId') {
        throw new Error('common__error--internal-server');
    }

    return data;
};

export const createGroup = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.addNewGroup}`;
    const response = await postDataAsync(url, payload);

    if (!response.status) {
        const errorMessage = errorsList[response.key] || 'common__error--internal-server';
        throw new Error(errorMessage);
    }
    return response.data;
};

export const createGroupByName = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.addNewGroupByName}`;
    const response = await postDataAsync(url, payload);

    if (!response.status) {
        const errorMessage = errorsList[response.key] || 'common__error--internal-server';
        throw new Error(errorMessage);
    }

    return response.data;
};

export const editGroupName = async (id, payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.editGroupByName}/${id}`;
    const response = await putDataAsync(url, payload);

    if (!response.status) {
        const errorMessage = errorsList[response.key] || 'common__error--internal-server';
        throw new Error(errorMessage);
    }

    return response.data;
};

export const removeGroup = async (groupId) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteGroup}?groupId=${groupId}`;

    let response;

    try {
        response = await deleteDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error(response.key);
    }
};

export const getGroup = async (groupId) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.editGroupDetails}?groupId=${groupId}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error(response.key);
    }

    return response.data;
};

export const moveItems = async (parentId, stores, groups) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.moveItems}`;

    let response;

    const payload = {
        parent: parentId,
        stores,
        groups,
    }

    try {
        response = await postDataAsync(url, payload);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error(response.key);
    }
};
