import React, { useState } from 'react';
import './ReportsMenu.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Config } from '../../../Config';
import NavigationServices from '../../Common/NavigationServices';
import classNames from 'classnames';

export const ReportsMenu = ({ menuItems }) => {
    const { t } = useTranslation();
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const url = Config.trainingPortalBaseUrl;
    const navigation = new NavigationServices();
    const getURL = (menu) => {
        return navigation.getSubMenuUrl(menu);
    };

    return (
        <>
            <ul
                onClick={handleClick}
                className={classNames(
                        'reports-menu',
                        'dropdown-menu',
                        {
                            clicked: click
                        }
                )}
            >
                {menuItems.map((item, index) => {
                    return (
                        <li key={index} className="dropdown-menu-item">
                            { item.title === 'reports__docs' ? (
                                <a
                                    href={t(item.path, { url })}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => setClick(false)}
                                >
                                    {t(item.title)}
                                </a>
                            ) :
                            ( <NavLink
                                activeClassName="active-menu-item"
                                className={({ isActive }) => (isActive ? 'headerMenu active-menu-item' : 'headerMenu')}
                                to={getURL(item.title)}
                                reloadDocument={true}
                                onClick={() => setClick(false)}
                            >
                                {t(item.title)}
                            </NavLink> )}
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
