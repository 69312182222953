import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import SettingService from '../Common/SettingService';
import StoresService from '../Common/StoresService';
import NavigationServices from '../Common/NavigationServices';
import AuthenticationService from '../Security/AuthenticationService';
import { Config } from '../../Config';
import { CommonConstants } from '../../Constants';
import Api from '../../Api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserAccountDetails } from '../../actions/userAccounts';
import { PortalServiceApiQuery } from '../../helpers/ApiQuery';
import { withReactRouter } from '../../HOCs/withReactRouter';
import PropTypes from 'prop-types';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';
import { ReportsMenu } from './ReportsMenu/ReportsMenu';
import { MenuItems } from './ReportsMenu/MenuItems';


/** */
class AdminSubHeader extends React.Component {
    /**
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.settings = new SettingService();
        this.stores = new StoresService();
        this.navigation = new NavigationServices();
        this.state = {
            CompanyType: 'Franchise',
            isOwner: 0,
            userUid: null,
            user: '',
            showReportsMenu: false
        };
        this.api = new Api();
        this.authService = new AuthenticationService(Config.authBaseUrl);
        this.state.user = this.authService.getUserEmail();
        this.state.userUid = this.authService.getUUID();
    }

    /** */
    componentDidMount() {
        const uuId = this.authService.getUUID();

        if (!uuId) {
            return;
        }

        // Fetching the user details.
        const url = new PortalServiceApiQuery({
            endpoint: CommonConstants.apiUrls.getUser,
            parameters: {
                uuId
            }
        }).toString();
        // TODO: add error handler-may fails and no sense to send next request
        this.api.getData(url, (data) => {
            const userObject = data.data;
            this.authService.setUserEmail(userObject.userEmail);
            this.setState({ user: this.authService.getUserEmail() });
        });
        // Fetching the account details.
        this.props.getUserAccountDetails(this.state.userUid, function() {
            const accountData = this.props.userAccounts[0];
            this.setState({ CompanyType: accountData.Company_Type });
        });
    }

    /** Handling navigation. */
    navigatation() {
        if (this.authService.isAdminAccountsPageSelected()) {
            window.location.href = '/admin/account?type=' + this.state.CompanyType + '&uuid=' + this.state.userUid;
        } else {
            window.location.href = '/settings/users/user?uuid=' + this.state.userUid;
        }
    }

    onMouseEnter = () => {
        this.setState({ showReportsMenu: true });
    };

    onMouseLeave = () => {
        this.setState({ showReportsMenu: false });
    };

    /**
   * Handling submenu.
   * @param {*} menu
   * @param {*} display
   * @return {*}
   */
    renderMenuItem(menu, display, type, isSubheaderMenuItem = false) {
        const active = window.location.pathname ? window.location.pathname.toLowerCase().indexOf(menu.toLowerCase()) !== -1 ? 'active_tab_sub' : '' : '';
        return  ( menu === 'reports' ?
        (<li
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
        >
            <a className={'headerMenu ' + active}
            >
                {display}
                <DropdownIcon className="hme-single-select__box__icon" />
            </a>
            {this.state.showReportsMenu && <ReportsMenu menuItems={MenuItems}/>}
        </li>)
        : (<li>
            <a className={'headerMenu ' + active} href={this.navigation.getSubMenuUrl(menu, type, isSubheaderMenuItem)} >{display}</a>
        </li>)
        );
    }

    needToShowAdminSubheader = () => {
        const { queryParams } = this.props;
        const queryUuid = queryParams.get('uuid');
        const localStorageUuid = this.authService.getUUID();
        const uuid = localStorageUuid || queryUuid;
        return this.settings.canShowAdminSubHeader(uuid);
    };

    /**
     * @return {*}
     */
    render() {
        const { CompanyType: companyType } = this.state;
        const { t } = this.props;

        if (this.needToShowAdminSubheader()) {
            return (
                <div>
                    <div className='subMenu menuBar '>
                        <div className='subHeaderSection'>
                            <ul className='subHeaders'>
                                {this.renderMenuItem('account', t('admin__sub-header__account'), companyType)}
                                {this.renderMenuItem('stores', t('common__stores'))}
                                {
                                    this.authService.isAdminAccountsPageSelected()
                                        ? this.renderMenuItem('users', t('common__users'))
                                        : this.renderMenuItem('user', t('common__user'))
                                }
                                {this.renderMenuItem('roles', t('common__roles'))}
                                {this.renderMenuItem('dashboard', t('common__dashboard'))}
                                {this.renderMenuItem('reports', t('common__reports'))}
                                {this.renderMenuItem('lockDeviceSettings', t('sub-header--settings__lock-device-settings'))}
                            </ul>
                        </div>
                    </div>
                </div>
            );
        } else if (this.stores.canShowAdminSubHeader()) {
            return(
                <div>
                    <div className='subMenu menuBar '>
                        <div className='subHeaderSection'>
                            <ul className='subHeaders'>
                                {this.renderMenuItem('stores', t('common__stores'))}
                                {this.renderMenuItem('deviceUpgrades', t('settingsDevicesUpgrade'))}
                            </ul>
                        </div>
                    </div>
                </div>
            )
        } else {
            return '';
        }
    }
}

AdminSubHeader.propTypes = {
    queryParams: PropTypes.object
};

/**
 * setting the props to state.
 * @param {*} state
 * @return {*}
 * */
function mapStateToProps(state) {
    return {
        headers: state.headers
    };
}

/**
 * Binding the actions.
 * @param {Function} dispatch
 * @return {*}
 * */
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserAccountDetails
    }, dispatch
    );
}

export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation(),
        withReactRouter
)(AdminSubHeader);
