import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GridActions } from 'library/GridActions';
import { Button } from 'library/Button';
import { OnOff } from 'library/OnOff';
import { ADMIN_ROUTES } from 'constants/routes';

const buttonVariants = ['transparent'];

const ActionsComponent = ({ openModal, domain, email, id, setDelIdx }) => {
    const { t } = useTranslation();
    const [isSSOEnabled, setIsSSOEnabled] = useState(false);
    const navigate = useNavigate();

    const onSSOEnableChange = useCallback(() => {
        setIsSSOEnabled(!isSSOEnabled);
    }, []);

    const routeToEdit = useCallback(() => {
        navigate(`${ADMIN_ROUTES.editSSO}`, { state: {
            domain,
            email,
            id
        } });
    }, [openModal, domain, email, id, setDelIdx]);

    const removeElement = useCallback(() => {
        openModal();
        setDelIdx(id);
    }, [openModal, domain, email, id, setDelIdx]);

    return (
        <GridActions className="hme-token-management__actions">
            <div className="hme-on-off-button">
                <OnOff
                    checked={isSSOEnabled}
                    onChange={onSSOEnableChange}
                    disabled={false}
                    isReadOnly={false}
                />
            </div>
            <Button
                variants={buttonVariants}
                onClick={routeToEdit}
            >
                {t('common__edit')}
            </Button>
            <Button
                variants={buttonVariants}
                onClick={removeElement}
            >
                {t('common__delete')}
            </Button>
        </GridActions>
    );
};

export const Actions = memo(ActionsComponent);
