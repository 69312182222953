import React from 'react';
import { Grid } from 'components/Common/Grid';

export const headers = [
    {
        text: 'common__account',
        property: 'account',
        flex: 4
    },
    {
        text: 'common__actions',
        property: 'actions',
        flex: 1,
        headerClassName: 'hme-grid-centered-cell'
    }
];

export const List = ({ emailList, ...props }) => {
    return (
        <Grid
            rows={emailList}
            headers={headers}
            rowKey="id"
            className="hme-sso-management-page__list"
            {...props}
        />
    );
};
