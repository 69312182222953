import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { getAdminStoreDataFromApi, updateStore } from 'services/Stores';
import { getSubscriptionsValue } from '../../Common/helpers/getSubscriptionsValue';
import { isValuesEqual } from '../../Common/helpers/isValuesEqual';
import { getDeviceRows } from '../helpers/getDeviceRows';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { ERROR_MESSAGE_T_KEY, SUCCESS_MESSAGE_T_KEY } from '../../constants/messagesTKeys';
import { useLoadStoreSystemTiers } from '../../../Common/hooks';
import { isDistributor } from 'services/Auth';
import { CommonConstants } from 'Constants';
import { getDeviceSubscription } from '../../Common/helpers/getDeviceSubscription';
import { getTiersByDeviceType } from '../../Common/helpers/getTiersByDeviceType';
import { DEFAULT_SUBSCRIPTION } from '../../Admin/hooks/useViewEdit';

const { XmlUtilities } = require('@hme-cloud/utility-common');

const { deviceType: deviceTypesMap, defaultDropdownOption } = CommonConstants;

const getStoreDetailsFromApi = async ({
    suid,
    setStoreName,
    setStoreDetails,
    setDeviceDetails,
    setStoreDetailsLoading,
    setNexeoSystemTierID,
    setJTechTierID,
    setVaioSubscription,
    setInitialVaioProvider,
    setVaioSubscriptionList
}) => {
    try {
        setStoreDetailsLoading(true);
        const {
            storeDetails: storeData,
            Device_Details,
            systemTiers,
            storeVaioProvider,
            vaioProviders
        } = await getAdminStoreDataFromApi(suid);
        const deviceData = Array.isArray(Device_Details) ? Device_Details : [];
        const [nexeoSystemTier] = getTiersByDeviceType(deviceTypesMap.nexeo.id, systemTiers);
        const [jTechTier] = getTiersByDeviceType(deviceTypesMap.linkWear.id, systemTiers);

        setNexeoSystemTierID(nexeoSystemTier?.id || '');
        setJTechTierID(jTechTier?.id || DEFAULT_SUBSCRIPTION.id);
        setStoreName(storeData.Store_Name);
        setStoreDetails({
            ...storeData,
            nexeoSystemTier
        });
        setDeviceDetails(deviceData);
        setVaioSubscriptionList(defaultDropdownOption.concat(vaioProviders));

        if (storeVaioProvider) {
            setInitialVaioProvider(storeVaioProvider);
            setVaioSubscription(storeVaioProvider.id);
        }
    } catch (error) {
        addErrorNotification('common__error--internal-server');
    } finally {
        setStoreDetailsLoading(false);
    }
};

export const useViewEdit = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [storeDetails, setStoreDetails] = useState({});
    const [isStoreDetailsLoading, setStoreDetailsLoading] = useState(false);
    const [deviceDetails, setDeviceDetails] = useState([]);
    const [storeName, setStoreName] = useState('');
    const [rows, setRows] = useState([]);
    const [nexeoSystemTierID, setNexeoSystemTierID] = useState('');
    const [jTechTierID, setJTechTierID] = useState(DEFAULT_SUBSCRIPTION.id);
    const [initialVaioProvider, setInitialVaioProvider] = useState(DEFAULT_SUBSCRIPTION);
    const [vaioSubscription, setVaioSubscription] = useState(-1);
    const [vaioSubscriptionList, setVaioSubscriptionList] = useState([]);

    const suid = useMemo(() => queryParams.get('suid'), [queryParams]);

    const showAccountEmail = useMemo(()=>isDistributor(), [])

    const { storeSystemTiers, isStoreSystemTiersLoading } = useLoadStoreSystemTiers();

    useEffect(() => {
        getStoreDetailsFromApi({
            suid,
            setStoreName,
            setStoreDetails,
            setDeviceDetails,
            setStoreDetailsLoading,
            setNexeoSystemTierID,
            setJTechTierID,
            setInitialVaioProvider,
            setVaioSubscription,
            setVaioSubscriptionList
        });
    }, [suid]);

    useEffect(() => {
        setRows(getDeviceRows(deviceDetails));
    }, [deviceDetails]);

    const { isNitroSubscription, isNexeoCustomerOnlySubscription, isJTechCustomerOnlySubscription } = useMemo(() =>
        getSubscriptionsValue(String(storeDetails['Account_Subscription_ID'])),
    [storeDetails['Account_Subscription_ID']]);

    const { isNVDEnabled } = getDeviceSubscription(deviceDetails);

    const onCancel = useCallback(() => navigate(-1), [navigate]);

    const onSave = useCallback(async () => {
        try {
            setStoreDetailsLoading(true);

            const { Store_UID: storeUid, Store_ID: storeID } = storeDetails;
            const storeNewData = {
                isAdmin: 0,
                StoreName: storeName,
                suid: storeUid,
                storeID,
                systemTierIDs: nexeoSystemTierID ? [nexeoSystemTierID] : [],
            };

            if (!XmlUtilities.isXMLCompatible(storeNewData.StoreName)) {
              setStoreDetailsLoading(false);
              addErrorNotification('stores__error--invalid-store-name');
            } else {
              await updateStore(storeNewData);
              await getStoreDetailsFromApi({
                  suid,
                  setStoreName,
                  setStoreDetails,
                  setDeviceDetails,
                  setStoreDetailsLoading: () => {},
                  setNexeoSystemTierID,
                  setJTechTierID,
                  setInitialVaioProvider,
                  setVaioSubscription,
                  setVaioSubscriptionList
              });
              setStoreDetailsLoading(false);
              addSuccessNotification(SUCCESS_MESSAGE_T_KEY);
            }
        } catch (error) {
            setStoreDetailsLoading(false);
            addErrorNotification(ERROR_MESSAGE_T_KEY);
        }
    }, [
        storeDetails,
        storeName,
        nexeoSystemTierID
    ]);

    const onVaioSubChange = (e) => {
        setVaioSubscription(e);
    };

    const onJTechTierChange = (jTechTier) => {
        setJTechTierID(jTechTier);
    };

    const isSaveBtnDisabled = useMemo(
        () =>
            storeName === storeDetails.Store_Name &&
            isValuesEqual(nexeoSystemTierID, storeDetails?.nexeoSystemTier?.id),
        [storeName, storeDetails, nexeoSystemTierID],
    );

    return {
        storeDetails,
        isStoreDetailsLoading,

        isNVDEnabled,
        isNitroSubscription,
        isNexeoCustomerOnlySubscription,
        isJTechCustomerOnlySubscription,
        jTechTierID,
        onJTechTierChange,
        vaioSubscription,
        initialVaioProvider,
        vaioSubscriptionList,
        onVaioSubChange,

        storeName,
        setStoreName,
        deviceDetails,
        rows,
        nexeoSystemTierID,
        storeSystemTiers,
        isStoreSystemTiersLoading,
        onCancel,
        onSave,
        isSaveBtnDisabled,
        showAccountEmail
    };
};
