
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { isAdmin } from 'services/Auth';
import { compose } from 'ramda';
import { CreateSSOManagement } from './CreateSSOManagement';
import { EditSSOManagement } from './EditSSOManagement';
import { Footer } from './components/Footer';
import { ADMIN_ROUTES } from 'constants/routes';
import { saveSSOInfo, updateSSOInfo } from '../services/ssoManagementService';
import { addErrorNotification } from 'services/Notifications';
import './CreateEditSSOManagement.scss';

const CreateEditSSOManagementComponentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isSaving, setIsSaving] = useState(true);
    const [formValue, setFormValue] = useState({});
    const [params, setParams] = useState();
    const { t } =useTranslation();

    useEffect(() => {
        let dom;
        let mail;
        let id;
        setIsEditMode(location.pathname.includes('edit') ? true : false);
        if (location.state) {
            dom = location.state.domain ?? null;
            mail = location.state.email ?? null;
            id = location.state.id ?? null;

            setParams({
                domain: dom,
                email: mail,
                id
            });
        }
        if (location.pathname.includes('edit') && (!dom || !mail || !id))
            navigate(ADMIN_ROUTES.ssoManagement);
    }, [location]);

    const onSave = useCallback(async () => {
        let ssoConfig;
        try {
            setIsSaving(true);
            if (isEditMode) {
                ssoConfig = await updateSSOInfo({
                    id: params.id, // SSOID
                    ...formValue
                });
            } else {
                ssoConfig = await saveSSOInfo(formValue);
            }
            setIsSaving(true);
            if (ssoConfig.status)
                navigate(ADMIN_ROUTES.ssoManagement, { state: { saved: true } });
            else
                addErrorNotification(t('settings_sso_configuration_save_error_message'));
        } catch (err) {
            addErrorNotification(t('settings_sso_configuration_save_error_message'));
            console.error('Could not save SSO info', err);
        }
    }, [formValue, isSaving, isEditMode, params]);

    const onCancel = useCallback(() => {
        navigate(ADMIN_ROUTES.ssoManagement);
    }, []);

    return (
        <>
            <div className="hme-tab-panel-wrapper">
                { isEditMode ? (
                    <EditSSOManagement
                        domain={params.domain}
                        email={params.email}
                        setIsSaving={setIsSaving}
                        setFormValue={setFormValue}
                    />
                ) : (
                    <CreateSSOManagement
                        setIsSaving={setIsSaving}
                        setFormValue={setFormValue}
                    />
                )}
            </div>
            <Footer
                isSubmitEnabled={!isSaving}
                onSave={onSave}
                onCancel={onCancel}
            />
        </>
    );
};


export const CreateEditSSOManagement = compose(
        withAccess({
            checkAccess: () => isAdmin()
        }),
        withHMELayout({
            contentClasses: ['hme-create_edit-sso-management-page']
        })
)(CreateEditSSOManagementComponentPage);
