import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getFormProps, getInputProps, useForm } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

import { Label } from 'library/Label';
import { Field, Button as FormButton, StatusButton } from 'library/FormControllers';
import { Title } from 'library/Title';
import { dismissNotification } from 'services/Notifications';

import { ReactComponent as WarningIcon } from 'assets/icons/alert.svg';

import './Form.scss';

const LoginSchema = z.object({
    username: z.string().min(3).max(100),
    password: z.optional(z.string().min(8).max(64))
});

export const Form = ({ attempts, maxAttempts, isExceeded, onLogin, isPartner, onVerifyUsername }) => {
    const { t } = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const isVerifyDisabled = username.length < 3;
    const isLoginDisabled = password.length < 8;

    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const originalUsername = useRef('');

    const [form, fields] = useForm({
        onValidate({ formData }) {
            return parseWithZod(formData, { schema: LoginSchema });
        },
        async onSubmit(event, { formData, submission }) {
            event.preventDefault();

            const intent = formData.get('intent');
            const username = formData.get('username');
            const password = formData.get('password');

            switch (intent) {
                case 'verify': {
                    originalUsername.current = username;

                    dismissNotification();
                    setIsPasswordShown(true);
                    onVerifyUsername();

                    break;
                }
                case 'login': {
                    if (!password) {
                        return submission.reply({
                            fieldErrors: { password: t('common__error--required') }
                        });
                    }

                    try {
                        setIsLoading(true);

                        await onLogin({ username, password });
                        return submission.reply();
                    } finally {
                        setIsLoading(false);
                    }
                }
                default:
                    break;
            }

            return submission.reply();
        },
        defaultValue: {
            username: originalUsername.current
        },
        shouldValidate: 'onBlur',
        shouldRevalidate: 'onBlur'
    });

    const handleCancel = () => {
        setIsPasswordShown(false);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);

        if (!(isPasswordShown && event.target.value !== originalUsername.current)) {
            return;
        }

        handleCancel();
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    if (isExceeded) {
        return (
            <FormContainer isPasswordShown>
                <AttemptsLabel attempts={5} maxAttempts={5} />
                <Label className="hme-login-form__attempts-exceeded">{t('login__attempts__exceeded')}</Label>
            </FormContainer>
        );
    }

    return <FormContainer isPartner={isPartner} isPasswordShown={isPasswordShown}>
        <form {...getFormProps(form)}>
            {isPasswordShown && attempts !== 0 && <AttemptsLabel attempts={attempts} maxAttempts={maxAttempts} />}

            <Field
                labelProps={{
                    children: t('common__user__username')
                }}
                inputProps={{
                    ...getInputProps(fields.username, { type: 'text' }),
                    maxLength: 100,
                    autoFocus: true,
                    autocomplete: 'username webauthn',
                    onChange: handleUsernameChange
                }}
                variant="inline"
            />
            {!isPasswordShown ? (
                <div className="hme-login-form__buttons">
                    <StatusButton
                        type="submit"
                        variant="primary"
                        size="lg"
                        name="intent"
                        value="verify"
                        disabled={isVerifyDisabled || isLoading}
                        status={isLoading ? 'pending' : 'idle'}
                    >
                        {t('common__continue')}
                    </StatusButton>
                </div>
            ) : (<>
                <Field
                    labelProps={{
                        children: t('common__user-password')
                    }}
                    inputProps={{
                        ...getInputProps(fields.password, { type: 'password' }),
                        maxLength: 64,
                        autoFocus: true,
                        onChange: handlePasswordChange
                    }}
                    variant="inline"
                />
                <div className="hme-login-form__buttons">
                    <FormButton type="button" onClick={handleCancel}>{t('common__cancel')}</FormButton>
                    <StatusButton
                        type="submit"
                        size="lg"
                        variant="primary"
                        name="intent"
                        value="login"
                        disabled={isLoginDisabled || isLoading}
                        status={isLoading ? 'pending' : 'idle'}
                    >
                        {t('login__login-button')}
                    </StatusButton>
                </div>
            </>)}
        </form>
    </FormContainer>;
};

const FormContainer = ({ children, isPartner, isPasswordShown }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="hme-login-form">
                <Title as="h2">{t('login__login-button')}</Title>

                <div className="hme-login-form__container">{children}</div>

            </div>
            {isPasswordShown && (
                <Link
                    to={isPartner ? '/partner/manageAccount/forgotpassword' : '/manageAccount/forgotpassword'}
                    className="hme-client-login-page__forgot-link"
                >
                    {t('login__forget-pass-link')}
                </Link>
            )}
        </>
    );
};

const AttemptsLabel = ({ attempts, maxAttempts }) => {
    const { t } = useTranslation();

    return (
        <Label className="hme-login-form__attempts">
            <WarningIcon className="hme-login-form__attempts__icon"/>
            <span className="hme-login-form__attempts_text">{t('login__attempts', { attempts, maxAttempts })}</span>
        </Label>
    );
};
