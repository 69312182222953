import React from 'react';
import { DateLib } from '@hme-cloud/utility-common';
import { ViewEditAction } from '../ViewEditAction';
import alertIcon from '../../../../assets/icons/alert.svg';
import errorIcon from '../../../../assets/icons/errorIcon.svg';
import { Tooltip } from 'react-tooltip'
import { Constant } from '@hme-cloud/utility-common';

const { VaioDefaults } = Constant;

const dateFormatForKeyExpiration = DateLib.FORMAT_TYPES.DATE_ONLY;
const dateFormatForUpdate = DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME;

const formatDateTime = (date) => {
    return date && new DateLib(new Date(date)).format(dateFormatForUpdate)
};

const expirationCheck = (expDate, currDate) => {
    if (!expDate) {
        return { className: '', icon: '', tooltip: '' };
    }

    const dayDifference = expDate.diff(currDate, 'days', true);
    if (dayDifference < 0) {
        return { className: 'hme-grid-row-red-background', icon: errorIcon, tooltip: 'common__expired' };
    } else if (dayDifference <= 10) {
        return { className: 'hme-grid-row-yellow-background', icon: alertIcon, tooltip: 'common__expiring' };
    }

    return { className: '', icon: '', tooltip: '' };
};

// Need to remove minute/second from date to compare
export const getYearMonthDay = (date = new Date()) => {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return [year, month, day];
};

/**
 * Format rows for Vaios Providers Grid
 * @param  {Array} vaioProviders list of providers
 * @param  {Function} handlePopup handle popup for confirming delete
 * @param  {Function} t handle translations
 * @return {Array} formatted provider details
 */

export const formatRows = (vaioProviders, handlePopup, t) => {
    const currDate = new DateLib().startOfDay();

    return vaioProviders.map((row) => {
        const keyExpDate = row.keyExpiration ? new DateLib(row.keyExpiration).startOfDay() : null;
        const { className, icon, tooltip } = expirationCheck(keyExpDate, currDate);

        const rowId = String(row.id);

        return {
            ...row,
            keyExpirationDate: (
                keyExpDate && <>
                    {keyExpDate.format(dateFormatForKeyExpiration)}
                    {icon &&
                        <>
                            <Tooltip id={rowId} place="bottom" positionStrategy="fixed" />
                            <img
                                src={icon}
                                alt="Alert Icon"
                                className="vaio-providers-alert-icon"
                                data-tooltip-content={t(tooltip)}
                                data-tooltip-variant="dark"
                                data-tooltip-id={rowId}
                            />
                        </>
                    }
                </>),
            createdDate: formatDateTime(row.createdDate),
            updatedDate: formatDateTime(row.updatedDate),
            originalUpdatedDate: row.updatedDate,
            Provider_Actions: <ViewEditAction onRemoveClick={handlePopup} provider={row} canRemove={!row.Store_Count} />,
            botAudioSourceType: VaioDefaults.botProcessingType[row.botAudioSourceType],
            rowClassName: className
        };
    });
};
