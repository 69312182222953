import React from 'react';
import PropTypes from 'prop-types';

export const Subscriptions = ({ nitroSubcription, tierSubscriptions }) => {
    return (
        <div className="hme-stores-status__list__subscription">
            {[nitroSubcription, ...tierSubscriptions].filter(Boolean).map((subscription) => (
                <div className="hme-stores-status__list__inner-cell" key={subscription}>
                    {subscription}
                </div>
            ))}
        </div>
    );
};

Subscriptions.propTypes = {
    nitroSubcription: PropTypes.string.isRequired,
    tierSubscriptions: PropTypes.arrayOf(PropTypes.string).isRequired
};
