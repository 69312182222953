import { checkAccess } from '../../../actions/permissions';
import { CommonConstants } from '../../../Constants';

export const getToolsSubHeadersPermissions = {
    manageBlockedEmails: CommonConstants.adminPermissions.EditAccounts,
    customerNotifications: CommonConstants.adminPermissions.CustomerNotification,
    vaioProviders: CommonConstants.adminPermissions.CreateVaioProvider,
    dxsFeeds: CommonConstants.adminPermissions.ManageDXS,
    tokenManagement: CommonConstants.adminPermissions.ManageToken
};

const tabsOrder = ['manageBlockedEmails', 'customerNotifications', 'vaioProviders',
    'dxsFeeds', 'firmwareManagement', 'tokenManagement']; // add 'ssoManagement' to the list

export const getToolsSubHeaders = () => {
    return tabsOrder
            .filter((tabName) => {
                const permission = getToolsSubHeadersPermissions[tabName];

                return !permission || checkAccess(getToolsSubHeadersPermissions[tabName]);
            });
};
