import React, { useMemo, useEffect } from 'react';
import { compose } from 'ramda';
import classNames from 'classnames';

import { hasGamificationFeature } from 'actions/permissions';
import { MobileHeader } from 'library/Header';
import { MainHeader, getActiveTab } from '../MainHeader';
import { ClientTitle, HOME_TAB_NAME } from './ClientTitle';
import { navigationService } from '../../Common/NavigationServices';
import { getMenuItems } from './menuItems';
import { checkBaseAccessForAccounts } from 'helpers/distributor';
import { NotificationsCenterLink } from 'components/Header/MenuItem/NotificationsCenterLink';
import { hasTextConnectAccess } from 'helpers/access';

import '../Header.css';
import './ClientHeader.scss';
import { withSubscriptionBasedExclusions } from '../../../HOCs/withSubscriptionBasedExclusions';
import { useLocation } from 'react-router-dom';

const menuItemsConfig = () => [{
    name: HOME_TAB_NAME,
    tKey: 'main-header__item--welcome',
    icon: 'home',
    hasAccess: true,
}, {
    name: 'distributorAccounts',
    tKey: 'common__accounts',
    icon: 'accounts',
    hasAccess: checkBaseAccessForAccounts(),
}, {
    name: 'dashboard',
    tKey: 'common__dashboard',
    icon: 'dashboard',
    hasAccess: true,
}, {
    name: 'reports',
    tKey: 'common__reports',
    icon: 'reports',
    hasAccess: true,
}, {
    name: 'leaderBoards',
    tKey: 'common__leaderboards',
    icon: 'dashboard',
    hasAccess: true,
}, {
    name: 'stores',
    tKey: 'common__settings',
    icon: 'settings',
    hasAccess: true,
}];

const getTabs = () => {
    const smackTalkItem = hasGamificationFeature('smacktalk') ? [{
        name: 'smackTalk',
        tKey: 'common__smack-talk',
        additionalClasses: 'smacktalk_icon',
        url: navigationService.getUrl('smackTalk'),
        hasAccess: true,
    }] : [];

    const textConnectItem = hasTextConnectAccess()
        ? [
              {
                  name: 'text-connect',
                  tKey: 'common__text-n-connect',
                  additionalClasses: 'text-connect_icon',
                  url: navigationService.getUrl('text-connect'),
                  hasAccess: true,
              },
          ]
        : [];

    const newItems = [...menuItemsConfig(), ...smackTalkItem, ...textConnectItem];

    return newItems.filter(({ hasAccess }) => hasAccess);
};

const ClientHeaderComponent = ({ userName, onLogout, filterBySubscription }) => {
    const tabs = filterBySubscription({ items: getTabs(), key: 'name' });
    const location = useLocation();
    const mobileItems = useMemo(() => filterBySubscription({ items: getMenuItems(), key: 'name' }), [location]);

    const { pathname } = location;

    const title = <ClientTitle activeTab={getActiveTab(tabs)} />;

    const hasRelative = pathname.includes('new-reports');

    return (
        <>
            <div className={classNames('hme-l-size main-header', { 'relative': hasRelative })}>
                <MainHeader menuItems={tabs} userName={userName} className='user-main-header' title={title} onLogout={onLogout}>
                    <NotificationsCenterLink />
                </MainHeader>
            </div>
            <div className='hme-s-size hme-header--client'>
                <MobileHeader menuItems={mobileItems} userName={userName} onLogout={onLogout} />
            </div>
        </>
    );
};

export const ClientHeader = compose(
    withSubscriptionBasedExclusions()
)(ClientHeaderComponent);
