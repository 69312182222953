import { DEFAULT_IP } from 'constants/login';
import Api from '../Api';
import { Config } from '../Config';
import { CommonConstants } from '../Constants';

// This function using call account Agree TOS api and return the results
export const accountAgreeTOS = function(data, callback) {
    const api = new Api();
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.agreeAccountTOS;
    const agreedata = {
        uuId: data.uuId,
        UserID: data.UserID,
        AccountTOSBrowser: navigator.userAgent,
        AccountTOSIP: DEFAULT_IP,
        ownerAccountId: data.ownerAccountId,
        AccountTOSAgree: 1
    };
    api.postData(url, agreedata, callback);
};

