import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { postDataAsync, getDataAsync, deleteDataAsync } from 'Api';

export const saveSSOInfo = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createSSOConfigDetailsAdmin}`;

    const { status, data = [] } = await postDataAsync(url, payload);

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return data;
};

export const updateSSOInfo = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updateSSOConfigDetailsAdmin}`;

    const { status, data = [] } = await postDataAsync(url, payload);

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return data;
};

export const allSSOConfig = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getSSOConfigDetailsAdmin}`;

    const { status, data = [] } = await getDataAsync(url, payload);

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return data;
};

export const deleteSSOInfo = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteSSOConfigDetailsAdmin}`;

    const { status, data = [] } = await deleteDataAsync(url, payload);

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return data;
};


