/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { compose, omit } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';
import { useLoadCountries } from 'hooks/useLoadCountries';
import { useLoadDistributors } from 'hooks/useLoadDistributors';
import { useLoadRegions } from 'hooks/useLoadRegions';
import { useLoadBrands } from 'hooks/useLoadBrands';
import { useLoadAccounDetails } from 'hooks/useLoadAccounDetails';
import { CenterLoader } from 'components/Common/CenterLoader';
import { Header } from './Header';
import { Footer } from './Footer';
import { AccountSection } from './AccountSection';
import { CompanySection } from './CompanySection';
import { SubscriptionSection } from './SubscriptionSection';
import { withReactRouter } from 'HOCs/withReactRouter';
import { sendActivationEmail } from './helpers/sendActivationEmail';
import { getAccountPermissions, getProfile, removeSelectedUUID } from 'services/Auth';
import {
    DEFAULT_REGIONS_COUNTRY_ID,
    ACCOUNTS_PAGE_URL,
    getAccountFormInitialValues,
    pageViews,
    pageTitlesByViews,
    getPageMode,
    mapAccountToFormData,
    saveAccount,
    updateAccount,
    formDataValidator,
    validationFieldsMessagesMap,
    getCompanyRegion,
    companyTypes
} from './helpers';
import { CommonConstants } from 'Constants';

import './Account.scss';

const { adminPermissions } = CommonConstants;

export const AccountComponent = ({ isDistributorMode }) => {
    const [queryParams] = useSearchParams();
    const pageMode = useMemo(() => getPageMode(queryParams), [queryParams]);
    const [formData, setFormData] = useState(getAccountFormInitialValues(pageMode));
    const [isSaving, setIsSaving] = useState(false);
    const [isResendingActivationEmail, setIsResendingActivationEmail] = useState(false);
    const [regionsCountryId, setRegionsCountryId] = useState(pageMode.pageViewType === pageViews.ADD ? DEFAULT_REGIONS_COUNTRY_ID : null);
    const [formErrors, setFormErrors] = useState({});
    const [isAccountInitialized, setIsAccountInitialized] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { regions, isRegionsLoading } = useLoadRegions(regionsCountryId);
    const { account, isAccountLoading } = useLoadAccounDetails({ queryParams });
    const { countries, isCountriesLoading } = useLoadCountries();
    const { distributors, isDistributorsLoading } = useLoadDistributors();
    const { brands, isBrandsLoading } = useLoadBrands();

    const profile = useMemo(() => getProfile(), []);
    const accountPermissions = useMemo(() => getAccountPermissions(profile.admin), [profile]);
    const isReadOnly = !accountPermissions.includes(adminPermissions.CreateAccounts);

    const title = useMemo(() => isReadOnly ? pageTitlesByViews['VIEW'] : pageTitlesByViews[pageMode.pageViewType], [pageMode]);

    const isCompanyTypeDisabled = useMemo(() => pageMode.pageViewType === pageViews.EDIT, [pageMode]);

    const isDistributorCreationPage = useMemo(
            () => pageMode.pageAccountType === companyTypes.DISTRIBUTOR && pageMode.pageViewType === pageViews.ADD,
            [pageMode]
    );

    const reInitRegions = useCallback(() => {
        const { companyRegionId, companyRegion } = getCompanyRegion({ account, regions });
        setFormData((prevFormData) => ({
            ...prevFormData,
            companyInfo: {
                ...prevFormData.companyInfo,
                companyRegionId,
                region: companyRegion
            }
        }));
    }, [account, regions]);

    const onAccountSave = useCallback(async () => {
        const errors = formDataValidator(formData);

        if (errors.status) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});

        if (pageMode.pageViewType === pageViews.ADD) {
            await saveAccount({ formData, regions, setIsSaving, navigate, t });
        }

        if (pageMode.pageViewType === pageViews.EDIT) {
            await updateAccount({ account, formData, regions, setIsSaving, navigate, t });
        }
    }, [formData, regions, setIsSaving, navigate, t]);

    const resetFormErrors = useCallback(
            (dataInForm, updatedFields) => {
            // find specific updated field in the form
                const changedField = Object.keys(updatedFields).find(
                        (fieldKey) => updatedFields[fieldKey] !== dataInForm[fieldKey]
                );

                if (changedField) {
                    const formErrorsToRemove = validationFieldsMessagesMap[changedField] || [];
                    setFormErrors(omit(formErrorsToRemove, formErrors));
                }
            },
            [formErrors, setFormErrors]
    );

    const onAccountCancel = useCallback(() => navigate(ACCOUNTS_PAGE_URL), [navigate]);

    const onAccountClose = useCallback(() => navigate(-1), [navigate]);

    const onAccountOwnerInfoChange = useCallback(
            (accountOwnerInfo) => {
                resetFormErrors(formData.accountOwnerInfo, accountOwnerInfo);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    accountOwnerInfo: {
                        ...prevFormData.accountOwnerInfo,
                        ...accountOwnerInfo
                    }
                }));
            },
            [formData, resetFormErrors]
    );

    const onAccountSettingsChange = useCallback(
            (accountSettings) => {
                resetFormErrors(formData.accountSettings, accountSettings);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    accountSettings: {
                        ...prevFormData.accountSettings,
                        ...accountSettings
                    }
                }));
            },
            [formData, resetFormErrors]
    );

    const onCompanyInfoChange = useCallback(
            (companyInfo) => {
                resetFormErrors(formData.companyInfo, companyInfo);
                setFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        companyInfo: {
                            ...prevFormData.companyInfo,
                            ...companyInfo
                        }
                    };
                });

                if (companyInfo.companyCountryId) {
                    setRegionsCountryId(companyInfo.companyCountryId);
                }
            },
            [formData, resetFormErrors]
    );

    const onSubscriptionTypeChange = useCallback(
            (subscriptionType) => {
                resetFormErrors(formData.subscriptionType, subscriptionType);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    subscriptionType: {
                        ...prevFormData.subscriptionType,
                        ...subscriptionType
                    }
                }));
            }, [formData, resetFormErrors]);

    const onAccountStatusChange = useCallback(
            (accountStatus) => {
                resetFormErrors(formData.accountStatus, accountStatus);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    accountStatus: {
                        ...prevFormData.accountStatus,
                        ...accountStatus
                    }
                }));
            },
            [formData, resetFormErrors]
    );

    const onEngineeringSettingsChange = useCallback(
            (engineeringSettings) => {
                resetFormErrors(formData.engineeringSettings, engineeringSettings);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    engineeringSettings: {
                        ...prevFormData.engineeringSettings,
                        ...engineeringSettings
                    }
                }));
            },
            [formData, resetFormErrors]
    );

    const onResendActivationEmail = useCallback(() => {
        sendActivationEmail({
            account,
            setIsSendingEmail: setIsResendingActivationEmail,
            navigate
        });
    }, [account, navigate]);

    useEffect(() => {
        if (pageMode.pageViewType !== pageViews.ADD && account) {
            setRegionsCountryId(account.Company_Country_ID);
            setFormData(mapAccountToFormData(account, regions));
            setIsAccountInitialized(true);
        }
    }, [account]);

    useEffect(() => {
        if (pageMode.pageViewType !== pageViews.ADD && account) {
            // reinit after regions are loaded
            reInitRegions();
        }
    }, [regions]);

    useEffect(() => {
        return () => {
            removeSelectedUUID();
        };
    }, []);

    const isLoading =
        isCountriesLoading ||
        isDistributorsLoading ||
        isBrandsLoading ||
        isAccountLoading ||
        (pageMode.pageViewType !== pageViews.ADD && !isAccountInitialized);

    return (
        <>
            <div className={classNames('hme-page-component', 'hme-account-wrapper')}>
                <NotificationsList />
                {isLoading || isSaving ? (
                    <CenterLoader>{t('common__loading')}</CenterLoader>
                ) : (
                    <div
                        className={classNames(
                                'hme-components',
                                'hme-account',
                            `hme-account-${pageMode.pageViewType.toLowerCase()}`
                        )}
                    >
                        <Header title={isReadOnly ? 'View Account' : title} />
                        <div className={`hme-components hme-account-content ${isReadOnly && 'hme-account-readonly'}`}>
                            <AccountSection
                                accountOwnerInfoInitialValues={formData.accountOwnerInfo}
                                accountSettingsInitialValues={formData.accountSettings}
                                formErrors={formErrors}
                                onAccountOwnerInfoChange={onAccountOwnerInfoChange}
                                onAccountSettingsChange={onAccountSettingsChange}
                                isReadOnly={isReadOnly}
                            />
                            <CompanySection
                                isCompanyTypeDisabled={isCompanyTypeDisabled}
                                isDistributorCreationPage={isDistributorCreationPage}
                                companyInfoInitialValues={formData.companyInfo}
                                distributors={distributors}
                                countries={countries}
                                regions={regions}
                                isRegionsLoading={isRegionsLoading}
                                brands={brands}
                                formErrors={formErrors}
                                onCompanyInfoChange={onCompanyInfoChange}
                                isReadOnly={isReadOnly}
                            />
                            <SubscriptionSection
                                accountStatusInitialValues={formData.accountStatus}
                                subscriptionTypeInitialValues={formData.subscriptionType}
                                engineeringSettingsInitialValues={formData.engineeringSettings}
                                formErrors={formErrors}
                                onSubscriptionTypeChange={onSubscriptionTypeChange}
                                onEngineeringSettingsChange={onEngineeringSettingsChange}
                                onAccountStatusChange={onAccountStatusChange}
                                isActiveAccount={account ? Boolean(account.Account_IsActive) : false}
                                isResendingActivationEmail={isResendingActivationEmail}
                                onResendActivationEmail={onResendActivationEmail}
                                isDistributorMode={isDistributorMode}
                                isReadOnly={isReadOnly}
                            />
                        </div>
                    </div>
                )}
            </div>
            {(!isDistributorMode || isReadOnly) &&
                <Footer
                    isSubmitEnabled={!isSaving}
                    onSave={onAccountSave}
                    onCancel={onAccountCancel}
                    onClose={onAccountClose}
                    isReadOnly={isReadOnly}
                />}
        </>
    );
};

AccountComponent.defaultProps = {
    isDistributorMode: false
};

AccountComponent.propTypes = {
    isDistributorMode: PropTypes.bool
};

export const Account = compose(
        withHMELayout({
            contentClasses: ['account-page']
        }),
        withReactRouter
)(AccountComponent);
