import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { cva } from 'cva';

import { Label } from '../Label';
import { Input } from '../Input';
import { ErrorList } from '../ErrorList';

import './Field.scss';

const field = cva('hme-form-field', {
    variants: {
        variant: {
            inline: 'hme-form-field--inline'
        }
    }
});

export const Field = ({ labelProps, inputProps, errors, className, variant }) => {
    const fallbackId = useId();
    const id = inputProps.id ?? fallbackId;
    const errorId = errors?.length ? `${id}-error` : null;

    return (
        <div className={field({ variant, className })}>
            <div className="hme-form-field__wrapper">
                <Label htmlFor={id} {...labelProps} />
                <Input
                    id={id}
                    aria-invalid={errorId ? true : null}
                    aria-describedby={errorId}
                    {...inputProps}
                />
            </div>
            <div className="hme-form-field__errors">
                {errorId ? <ErrorList id={errorId} errors={errors} className="" /> : null}
            </div>
        </div>
    );
};

Field.propTypes = {
    labelProps: PropTypes.object.isRequired,
    inputProps: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    variant: PropTypes.oneOf(['inline'])
};
