import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';

import { SuccessInput } from 'library/SuccessInput';
import { Loader } from './Loader';
import { SuggestionsList } from './SuggestionsList';

import './AutoCompleteInput.scss';

const Suggestions = cond([
    [({ isLoading }) => isLoading, Loader],
    [T, SuggestionsList]
]);

export const defaultMinValueLength = 3;

// It is Presental component it only displays current state.
// Recomendation: Add another wrapper component that is page specific to
// manipulate with data that you want to show
export const AutoCompleteInput = ({
    suggestions,
    headers,
    mobileHeaders,
    variants = [],
    isLoading = false,
    loadingMessage = 'autocomplete-input__loading',
    noSuggestionText,
    minValueLength = defaultMinValueLength,
    value,
    rowKey,
    Component = SuccessInput,
    onFocus,
    onBlur,
    onSuggested,
    checkDisabled,
    ...inputProps
}) => {
    const [inFocus, setInFocus] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setShowSuggestions(inFocus && value && value.length >= minValueLength);
        if (inputProps.disabled)
            setShowSuggestions(false);
    }, [inFocus, value, minValueLength]);

    return (
        <Component
            {...inputProps}
            value={value}
            variants={[...variants, 'auto-complete']}
            onFocus={() => {
                setInFocus(true);
                onFocus && onFocus();
            }}
            onBlur={() => {
                setInFocus(false);
                onBlur && onBlur();
            }}
        >
            {
                showSuggestions &&
                    <div className="hme-auto-complete-suggestions">
                        <Suggestions
                            suggestions={suggestions}
                            headers={headers}
                            mobileHeaders={mobileHeaders}
                            isLoading={isLoading}
                            loadingMessage={t(loadingMessage)}
                            onSuggested={onSuggested}
                            noSuggestionText={noSuggestionText}
                            rowKey={rowKey}
                            checkDisabled={checkDisabled}
                        />
                    </div>
            }
        </Component>
    );
};
