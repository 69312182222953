import React from 'react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';
import { Section } from 'library/Section';
import { CenterLoader } from 'components/Common/CenterLoader';
import { Toggle } from 'library/Toggle';
import { SingleSelect } from 'library/SingleSelect';
import { SaveFooter } from 'library/SaveFooter';
import { CheckboxGroup } from 'library/CheckboxGroup';
import { InputComponent as Input} from 'components/Inputs';
import { ErrorLabel } from 'library/ErrorLabel';

import { useViewEdit } from './hooks/useViewEdit';
import { Header } from './Header';

import './ViewEdit.scss';

const statusOptions = [{
    text: 'common__active',
    value: true,
}, {
    text: 'common__inactive',
    value: false,
}];

const ValidationErrors = ({ errors }) => {
    const { t } = useTranslation();

    if (!errors || errors.length === 0) {
        return null;
    }

    return (
        <>
            {
                errors.map((tKey) => (
                    <ErrorLabel key={tKey}>{t(tKey)}</ErrorLabel>
                ))
            }
        </>
    );
}

const ViewEditComponent = ({
    originPartner,
    partner,
    types,
    title = 'admin__partner__form__title--create',
    countries,
    regions,
    isLoading,
    isRegionsLoading,
    showStatus = true,
    showResend = false,
    onChange,
    onSave,
    onCancel,
    onResend,
    onMasquerade
}) => {
    const {
        countriesList,
        regionsList,
        typeOptions,
        confirmEmail,
        isSubmitEnabled,
        validationErrors,
        onNameChange,
        onTypesChange,
        onAddress1Change,
        onAddress2Change,
        onCountryIdChange,
        onRegionIdChange,
        onRegionChange,
        onLocalityChange,
        onPostCodeChange,
        onPhoneNumberChange,
        onMobileNumberChange,
        onUserOfficeNumberChange,
        onUserMobileNumberChange,
        onIsActiveChange,
        onFirstNameChange,
        onLastNameChange,
        onEmailChange,
        onConfirmEmailChange,
        onSubmitClick
    } = useViewEdit({ partner, originPartner, countries, regions, types, onChange, onSubmit: onSave });

    const { t } = useTranslation();

    if (isLoading) {
        return (
            <div className='hme-partner-form'>
                <NotificationsList />
                <Loader />
            </div>
        );
    }

    return (
        <div className='hme-partner-form'>
            <NotificationsList />
            {
                isLoading && <CenterLoader>{t('common__loading')}</CenterLoader>
            }
            <div className='hme-partner-form__sections'>
                <Header
                    title={title}
                    showResend={showResend}
                    onResend={onResend}
                    onMasquerade={onMasquerade}
                />
                <Section
                    title='admin__partner__form__title--info'
                    className='hme-partner-form__section--info'
                >
                    <Input
                        label='admin__partner__form__label--name'
                        value={partner.name}
                        maxLength={200}
                        isRequired={true}
                        name='partner-name'
                        onChange={onNameChange}
                    />
                    <Input
                        label='admin__partner__form__label--address1'
                        value={partner.address1}
                        maxLength={255}
                        name='partner-address1'
                        onChange={onAddress1Change}
                    />
                    <Input
                        label='admin__partner__form__label--address2'
                        value={partner.address2}
                        maxLength={255}
                        name='partner-address2'
                        onChange={onAddress2Change}
                    />
                    <SingleSelect
                        value={partner.countryId}
                        label={t('common__country')}
                        isRequired={true}
                        items={countriesList}
                        name='partner-country'
                        onChange={onCountryIdChange}
                    />
                    { regionsList.length ? (
                        <SingleSelect
                            value={partner.region}
                            label={t('common__state')}
                            items={regionsList}
                            isRequired={true}
                            isDisabled={isRegionsLoading}
                            name='partner-region-id'
                            onChange={onRegionIdChange}
                        />
                    ) : (
                        <Input
                            value={partner.region}
                            maxLength={100}
                            isRequired={true}
                            label="common__region"
                            isDisabled={isRegionsLoading}
                            name='partner-region'
                            onChange={onRegionChange}
                        />
                    )}
                    <Input
                        value={partner.locality}
                        maxLength={100}
                        label="common__city"
                        name='partner-localiy'
                        onChange={onLocalityChange}
                    />
                    <Input
                        value={partner.postCode}
                        maxLength={20}
                        label="common__zip"
                        name='partner-post-code'
                        onChange={onPostCodeChange}
                    />
                    <ValidationErrors errors={validationErrors.postCode} />
                    <Input
                        value={partner.phoneNumber}
                        maxLength={50}
                        label="common__phone-number"
                        name='partner-phone-number'
                        onChange={onPhoneNumberChange}
                    />
                    <ValidationErrors errors={validationErrors.phoneNumber} />
                    <Input
                        value={partner.mobileNumber}
                        maxLength={50}
                        label="common__mobile-phone"
                        name='partner-mobile-number'
                        onChange={onMobileNumberChange}
                    />
                    <ValidationErrors errors={validationErrors.mobileNumber} />
                </Section>
                <Section title="admin__partner__form__title--settings">
                    <CheckboxGroup
                        label='admin__partner__form__label--types'
                        items={typeOptions}
                        value={partner.types}
                        onChange={onTypesChange}
                        isRequired={true}
                    />
                    {
                        showStatus && (
                            <Toggle
                                label={t('admin__partner__form__label--status')}
                                value={partner.isActive}
                                onChange={onIsActiveChange}
                            >{statusOptions}</Toggle>
                        )
                    }
                </Section>
                <Section title='admin__partner__form__title--owner' className='hme-partner-form__section--owner'>
                    <Input
                        value={partner.firstName}
                        isRequired={true}
                        maxLength={50}
                        label="common__user__first-name"
                        name='partner-first-name'
                        onChange={onFirstNameChange}
                    />
                    <Input
                        value={partner.lastName}
                        isRequired={true}
                        maxLength={50}
                        label="common__user__last-name"
                        name='partner-last-name'
                        onChange={onLastNameChange}
                    />
                    <Input
                        value={partner.email}
                        isRequired={true}
                        maxLength={200}
                        label="common__email-address"
                        name='partner-email'
                        onChange={onEmailChange}
                    />
                    <ValidationErrors errors={validationErrors.email} />
                    <Input
                        value={confirmEmail}
                        isRequired={true}
                        maxLength={200}
                        label="account__section__form--confirm-email-address"
                        name='confirm-email'
                        onChange={onConfirmEmailChange}
                    />
                    <ValidationErrors errors={validationErrors.confirm} />
                    <Input
                        value={partner.userOfficeNumber || ''}
                        maxLength={50}
                        label="common__phone-number"
                        name="partner-phone-number"
                        onChange={onUserOfficeNumberChange}
                    />
                    <ValidationErrors errors={validationErrors.userOfficeNumber} />
                    <Input
                        value={partner.userMobileNumber || ''}
                        maxLength={50}
                        label="common__mobile-phone"
                        name="partner-mobile-number"
                        defaultValue=""
                        onChange={onUserMobileNumberChange}
                    />
                    <ValidationErrors errors={validationErrors.userMobileNumber} />
                </Section>
            </div>
            <SaveFooter onSave={onSubmitClick} onCancel={onCancel} isSubmitEnabled={isSubmitEnabled} />
        </div>
    );
};

export const ViewEdit = compose(
    withHMELayout()
)(ViewEditComponent);