import { useCallback, useEffect, useState } from 'react';
import { useAbortController } from 'hooks/useAbordController';
import { defaultMinValueLength } from 'library/AutoCompleteInput';
import { getAccountOwners } from '../../services/accountOwnerService';

export const useAccountOwnerEmails = () => {
    const [email, setEmail] = useState();
    const [matchedEmails, setMatchedEmails] = useState([]);
    const [isEmailLoading, setIsEmailLoading] = useState(false);

    const { abort, abortControllerRef } = useAbortController();

    const handleEmailChange = useCallback(async (newEmail = '') => {
        setIsEmailLoading(true);
        setEmail(newEmail);

        abort();

        if (newEmail.length < defaultMinValueLength) {
            setMatchedEmails([]);
            return [];
        }

        const suggestedEmails = await getAccountOwners(
                {
                    emailSearchQuery: newEmail
                },
                { signal: abortControllerRef.current.signal }
        );

        setMatchedEmails(suggestedEmails);
        setIsEmailLoading(false);

        return suggestedEmails;
    }, [abort]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, []);

    return { handleEmailChange, setEmail, email, matchedEmails, isEmailLoading };
};
