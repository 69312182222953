import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'library/Button';
import { ReactComponent as Pencil } from 'assets/icons/IconEditBlue.svg';

import './Action.scss';
import { getUUID, isAdmin } from 'services/Auth';

const getViewLink = (role, viewLink) => {
    const separator = viewLink.includes('?') ? '&' : '?';
    let uidParam = '';
    if (isAdmin()) {
        uidParam = `&uuid=${getUUID()}`;
    }
    if (role.isAccountOwner) {
        return `${viewLink}${separator}isAccountOwner=true${uidParam}`;
    }

    return `${viewLink}${separator}roleuid=${role.Role_UID}${uidParam}`;
};

const buttonVariants = ['transparent'];

export const Action = ({ role, viewLink, hasRemoveAccess, hasViewAccess, onRemove }) => {
    const { t } = useTranslation();
    const link = useMemo(() => getViewLink(role, viewLink), [role, viewLink]);

    const { isAccountOwner = false, UserName: linkedUsers = '' } = role;

    const viewText = useMemo(() => isAccountOwner ? t('common__view') : t('common__view-edit'), [role, t]);
    const onRemoveClick = useCallback(() => {
        onRemove && onRemove(role);
    }, [role, onRemove]);

    if (!hasViewAccess && !hasRemoveAccess) {
        return null;
    }

    if (!hasViewAccess && isAccountOwner) {
        return null;
    }

    return (
        <>
            {hasViewAccess &&
                <Link to={link} className='hme-roles__edit-action'>
                    {!isAccountOwner && <Pencil className="hme-roles__edit-icon" />}
                    <Button variants={buttonVariants}>{viewText}</Button>
                </Link>
            }
            {hasRemoveAccess && !isAccountOwner && !linkedUsers &&
                <Button
                    className='hme-roles__actions--remove'
                    variants={buttonVariants}
                    onClick={onRemoveClick}
                >{t('common__remove')}</Button>
            }
        </>
    );
};

Action.propTypes = {
    role: PropTypes.any,
    viewLink: PropTypes.string,
    hasRemoveAccess: PropTypes.bool,
    hasViewAccess: PropTypes.bool,
    onRemove: PropTypes.func,
};
