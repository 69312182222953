import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { SearchOptionable } from 'library/SearchOptionable';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { adminSystemsSelectSearchOptions } from '../constants/adminSystemsSelectSearchOptions';
import { EmailLink } from '../EmailLink';

import './Header.scss';

export const Header = ({
    searchParams,
    onSearchParamsChange,
    onSearch,
    isSearchDisabled,
    searchRef = null,
    filtersCount,
    onFiltersReset,
    onReportSend,
    isEmailLinkDisabled,
}) => {
    const { t } = useTranslation();

    return (
        <div className="hme-admin-systems-header">
            <div className="hme-admin-systems-header-title-wrapper">
                <Title>{t('main-header__item--systems')}</Title>
                <SearchOptionable
                    value={searchParams}
                    onChange={onSearchParamsChange}
                    onSearch={onSearch}
                    searchSelectOptions={adminSystemsSelectSearchOptions}
                    isDisabled={isSearchDisabled}
                    isSearchButtonDisabled={isSearchDisabled}
                    searchRef={searchRef}
                />
                <div className="hme-admin-systems-filters-count">
                    <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
                </div>
                <EmailLink onReportSend={onReportSend} isDisabled={isEmailLinkDisabled} />
            </div>
        </div>
    );
};
