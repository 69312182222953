import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { InputComponent } from 'components/Inputs';
import { List } from '../List';
import { Title } from 'library/Title';
import { Label } from 'library/Label';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { useAccountOwnerEmails } from '../../hooks/useAccountOwnerEmails';
import { SearchInput } from 'components/Inputs/SearchInput';
import { Actions } from '../Actions';

const suggestionsHeaders = [{
    text: 'Email',
    property: 'email'
}];

export const Sections = ({ domain: dom = '', email: mail ='', setIsSaving, setFormValue }) => {
    const [domain, setDomain] = useState(dom);
    const [emailList, setEmailList] = useState([]);
    const [typeAheadIsDisabled, setTypeAheadIsDisabled] = useState(false);
    const { t } = useTranslation();

    const {
        handleEmailChange: fetchAccountOwners,
        setEmail: setAccountOwnerEmail,
        email: accountOwnerEmail,
        matchedEmails: matchedAccountOwnerEmails,
        isEmailLoading
    } = useAccountOwnerEmails();

    const removeAccount = useCallback(() => {
        setEmailList([]);
        setAccountOwnerEmail('');
        setTypeAheadIsDisabled(false);
        setFormValue({
            domain: '',
            email: ''
        });
    }, [domain, mail, accountOwnerEmail, setIsSaving, setFormValue, emailList]);

    const handleEmailSelect = useCallback((emailSelected) => {
        const { email, Account_ID: accountID } = emailSelected;
        setAccountOwnerEmail(email);
        setEmailList([{
            id: 'sso-selected',
            account: email,
            accountID,
            actions: <Actions removeAccount={removeAccount}/>
        }]);
        setTypeAheadIsDisabled(true);
    }, [domain, mail, accountOwnerEmail, setIsSaving, setFormValue, emailList]);

    const handleEmailChange = useCallback(async (newEmail) => {
        try {
            await fetchAccountOwners(newEmail);
        } catch (err) {
            // do nothing when request is programmatically cancelled
        }
    }, [domain, mail, accountOwnerEmail, setIsSaving, setFormValue, emailList]);

    useEffect(() => {
        if (mail) {
            setAccountOwnerEmail(mail);
            setEmailList([{
                id: 'sso-selected',
                account: mail,
                actions: <Actions removeAccount={removeAccount}/>
            }]);
            setTypeAheadIsDisabled(true);
        }
    }, []);

    useEffect(() => {
        setIsSaving(!(emailList.length && domain));
        let form = {
            domain: domain.toLowerCase().trim()// saving domain in db in lowercase 
        };
        if (emailList.length) {
            if (emailList[0].account) {
                form = {
                    ...form,
                    email: emailList[0].account.trim()
                };
            } if (emailList[0].accountID) {
                form = {
                    ...form,
                    accountID: emailList[0].accountID
                };
            }
        }
        setFormValue(form);
    }, [domain, mail, emailList, accountOwnerEmail]);

    return <>
        <Section className="hme-section-sm hme-section-sm_section-1">
            <Title>{t('settings_sso_configuration_domain_information')}</Title>
            <div className="sso-form-input">
                <Label>{t('settings_sso_configuration_domain_label')}</Label>
                <InputComponent
                    value={domain}
                    isRequired={true}
                    onChange={setDomain}
                    id="domain"
                />
            </div>

        </Section>
        <Section className="hme-section-sm hme-section-sm_section-2">
            <Title>{t('settings_sso_configuration_select_SSO_Manager')}</Title>
            <div className="sso-form-input">
                <Label><span style={{ color: '#186df4' }}>*</span>{t('api-token-management__form--select-DXS-provider')}</Label>
                <AutoCompleteInput
                    disabled={typeAheadIsDisabled}
                    value={accountOwnerEmail}
                    onChange={handleEmailChange}
                    Component={SearchInput}
                    suggestions={matchedAccountOwnerEmails}
                    onSuggested={handleEmailSelect}
                    isLoading={isEmailLoading}
                    loadingMessage={t('autocomplete-input__no-suggestions')}
                    noSuggestionText={t('common__error--no-email-found')}
                    headers={suggestionsHeaders}
                    rowKey={suggestionsHeaders[0].property}
                    isRequired
                    autoComplete="off"
                    placeholder={t('api-token-management__form--token--DXS--account-email')}
                />
            </div>
            <div className="sso-form-input">
                {emailList.length > 0 && (<div>
                    <Title> {t('common__account') + ' ' + t('stores-hierarchy__move-popup__selected')} </Title>
                    <List
                        emailList={emailList}
                    />
                </div>)}
            </div>

        </Section>
    </>;
};
